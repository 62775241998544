import { createContext, useContext } from 'react';

const FormStyleContext = createContext({ backgroundColor: 'bg-base-warm-cream' }); // Default value

export const useFormStyle = () => useContext(FormStyleContext);

export const FormStyleProvider = ({
  children,
  backgroundColor,
}: {
  children: React.ReactNode;
  backgroundColor: string;
}) => {
  return (
    <FormStyleContext.Provider value={{ backgroundColor }}>{children}</FormStyleContext.Provider>
  );
};

export default FormStyleProvider;
