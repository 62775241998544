import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { useMediaQuery } from 'usehooks-ts';
import { TEXT_COLOR_MAP } from '../../../../constants/theme.constants';

interface Tagline {
  image: string;
  primaryText: string;
  secondaryText: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  imagePosition: 'Center' | 'Bottom';
}
const CLPTagLine = (props: { className?: string }) => {
  const { tagline: Tagline } = useStrapiCLPData();

  const textSets = Tagline.map((tagLine: Tagline) => {
    return {
      secondaryTextColor: TEXT_COLOR_MAP[tagLine.secondaryTextColor],
      primaryTextColor: TEXT_COLOR_MAP[tagLine.primaryTextColor],
      primaryText: tagLine.primaryText,
      secondaryText: tagLine.secondaryText,
      image: tagLine.image,
      position: tagLine.imagePosition,
    };
  });
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textSets.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [textSets.length]);

  const currentTextSet = textSets[currentIndex];
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  return (
    <div
      className={clsx(
        'flex w-full animate-textLoop items-start gap-2 overflow-visible text-base font-medium text-base-soft-black sm:text-xl md:text-2xl lg:text-3xl',
        props.className,
        isLargeScreen ? 'min-h-[150px]' : ''
      )}
    >
      <div className="relative">
        <span className={clsx('relative inline-block', currentTextSet.primaryTextColor)}>
          {currentTextSet.primaryText}
        </span>
        {/* SVG elements positioned outside the text container */}
        {currentTextSet.position === 'Bottom' && (
          <div className="absolute left-1/2 mt-1 w-[300%] -translate-x-1/2 lg:mt-2">
            <img
              src={currentTextSet.image.data.attributes.url}
              alt=""
              className="h-[24px] w-full object-cover"
            />
          </div>
        )}
        {currentTextSet.position === 'Center' && (
          <div
            className={clsx(
              'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
              'aspect-square w-[200%]'
            )}
          >
            <img src={currentTextSet.image.data.attributes.url} alt="" className="h-full w-full" />
          </div>
        )}
      </div>
      <span className={clsx('ml-2 xxs:ml-0', currentTextSet.secondaryTextColor)}>
        {currentTextSet.secondaryText}
      </span>
    </div>
  );
};

export default CLPTagLine;
