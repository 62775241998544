import { gql } from '@apollo/client';
export const EULAFragment = gql`
  fragment EULARichText on EulaRichTextEntityResponse {
    data {
      attributes {
        data
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
