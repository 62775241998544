import { gql } from '@apollo/client';

export const HQServicesFragment = gql`
  fragment HQServicesFragment on HqServiceEntityResponse {
    data {
      attributes {
        HeroSection {
          title
          subtitle
          description
          heroImage {
            data {
              attributes {
                url
              }
            }
          }
        }
        Services {
          title
          subtitle
          description
          slug
          isActive
          serviceTheme
          serviceImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Buttons {
            slug
            isActive
            link
            isExternal
            label
            style
            theme
            icon
          }
        }
        UserTypeSelector {
          infoText
          infoTextTheme
          Buttons {
            slug
            isActive
            link
            isExternal
            label
            style
            theme
            icon
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
