import clsx from 'clsx';
import { ArrowRight } from 'phosphor-react';
import Navbar from '../../../../components/Navbar';

interface HQServicesActionItemProps {
  label: string;
  to: string;
  isExternal?: boolean;
  buttonStyle: 'fill' | 'outline';
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
  state?: {
    preventScrollToTop?: boolean;
  };
}

const SpeakerActionItem = ({
  label,
  to,
  isExternal = false,
  buttonStyle,
  className,
  buttonClassName,
  iconClassName,
  state,
}: HQServicesActionItemProps) => {
  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      state={state && state}
      className={clsx(
        'group flex flex-1 flex-col justify-between gap-x-6 rounded-lg border px-6 py-3 text-base font-medium',
        buttonStyle === 'fill' ? buttonClassName : 'border-2 border-base-soft-black bg-transparent',
        className
      )}
    >
      <div className="flex items-center justify-between gap-4">
        <span className="font-medium">{label}</span>
        <ArrowRight
          size={20}
          className={clsx('transition-transform group-hover:translate-x-1', iconClassName)}
          aria-hidden="true"
        />
      </div>
    </Navbar.NavItem>
  );
};

export default SpeakerActionItem;
