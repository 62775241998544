import React from 'react';
import { Controller, Control, FieldValues, Path } from 'react-hook-form';
import Select, {
  OptionsOrGroups,
  GroupBase,
  SingleValue,
  MultiValue,
  ActionMeta,
} from 'react-select';
import clsx from 'clsx';

// Define the structure of the OptionType with value and label
interface OptionType {
  value: string;
  label: string;
}

// TypeScript interface for props
interface SelectControllerProps<T extends FieldValues> {
  name: Path<T>;
  id: string;
  control: Control<T>;
  options: OptionsOrGroups<OptionType, GroupBase<OptionType>>;
  placeholder: string;
  hasError: boolean;
  isMulti?: boolean;
  customComponents?: any;
}

const selectControlClass = (hasError: boolean) => {
  const baseClass = `
    !rounded-[10px] 
    !border-[1.5px] 
    px-4 
    py-2 
    !bg-transparent 
    text-base-soft-black 
    hover:!border-[1.5px] 
    focus:!outline-none 
    !shadow-none
  `;

  const borderColorClass = hasError ? '!border-error-border' : '!border-black';

  return `${baseClass} ${borderColorClass}`;
};

// Main SelectController component
const SelectController = <T extends FieldValues>({
  name,
  id,
  control,
  options,
  placeholder,
  hasError,
  isMulti = false,
  customComponents,
}: SelectControllerProps<T>) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value } }) => (
      <Select
        id={id}
        onBlur={onBlur}
        isMulti={isMulti}
        onChange={(
          newValue: MultiValue<OptionType> | SingleValue<OptionType> | null,
          _actionMeta: ActionMeta<OptionType>
        ) => {
          if (isMulti) {
            onChange(newValue as MultiValue<OptionType>);
          } else {
            onChange((newValue as SingleValue<OptionType>)?.value || null); // Ensure `null` is passed if no option is selected
          }
        }}
        value={
          isMulti
            ? (value as OptionType[]) // Use the full array of objects
            : (options as OptionType[]).find((option) => option.value === value) || null
        }
        options={options}
        placeholder={placeholder}
        classNames={{
          control: () => selectControlClass(hasError), // Apply class based on isMulti
          valueContainer: () => 'bg-transparent !px-[4px]',
          indicatorsContainer: () => (isMulti ? '!text-base-soft-black' : 'hidden'),
          dropdownIndicator: () => (isMulti ? 'hidden' : 'hidden'), // Hide for both, can be adjusted
          indicatorSeparator: () => (isMulti ? 'hidden' : 'hidden'), // Hide for both, can be adjusted
          clearIndicator: () => '!text-base-soft-black',
          input: () => 'text-base-soft-black',
          singleValue: () => (!isMulti ? '!text-base-soft-black text-sm' : ''), // Return a string instead of false
          multiValue: () => (isMulti ? '!bg-base-creme text-sm' : ''), // Return a string instead of false
          multiValueLabel: () => (isMulti ? '!text-base-soft-black pl-0' : ''), // Return a string instead of false
          multiValueRemove: () => (isMulti ? 'text-black hover:bg-red-500 hover:text-white' : ''), // Return a string instead of false
          menu: () => '!z-40 !bg-base-soft-beige',
          menuList: () => '!bg-base-soft-beige',
          option: () =>
            clsx(
              '!cursor-pointer !bg-base-soft-beige !text-sm !text-base-soft-black hover:!bg-base-creme'
            ),
          placeholder: () => '!text-base-soft-black text-sm font-light',
        }}
        components={customComponents}
      />
    )}
  />
);

export default SelectController;
