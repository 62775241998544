import clsx from 'clsx';
import { PropsWithChildren } from 'react';
interface FormFieldWrapperProps {
  className?: string;
}

const FloatingFormFieldWrapper = ({
  children,
  className,
}: PropsWithChildren<FormFieldWrapperProps>) => {
  return <div className={clsx('relative mt-2 w-full', className)}>{children}</div>;
};

export default FloatingFormFieldWrapper;
