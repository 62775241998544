import clsx from 'clsx';
import { Play, X } from 'phosphor-react';
import { useState, useEffect } from 'react';
import { Button } from '../Buttons';

export const VideoPlayer = ({
  src,
  altText,
  fallbackImage,
  showIcons,
  onPlay,
}: {
  src: string | undefined;
  altText: string | undefined;
  fallbackImage: string | undefined;
  showIcons: boolean;
  onPlay?: (isPlaying: boolean) => void;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  useEffect(() => {
    setIsButtonVisible(false);
    const timer = setTimeout(() => {
      setIsButtonVisible(true);
    }, 900);
    return () => clearTimeout(timer);
  }, [isExpanded]);

  const handlePlayClick = () => {
    setIsExpanded(true);
    if (onPlay) onPlay(true);
  };

  const handleCloseClick = () => {
    setIsExpanded(false);
    if (onPlay) onPlay(false);
  };

  if (!src) return <p>{altText}</p>;

  return (
    <div className="relative flex w-full justify-end overflow-hidden">
      <div
        className={clsx(
          'duration-800 ease-custom relative overflow-hidden transition-all',
          isExpanded
            ? 'max-h-[75vh] w-full rounded-[20px]'
            : 'max-h-[75vh] w-[90%] rounded-l-[20px]'
        )}
        style={{
          aspectRatio: '16/9',
          transition: 'all 0.8s cubic-bezier(0.25, 1, 0.5, 1)',
          transformOrigin: 'right center',
        }}
      >
        <div
          className={clsx(
            'duration-800 ease-custom relative h-full w-full transition-all',
            !isExpanded && 'md:translate-x-[5%] md:scale-110'
          )}
        >
          <video
            loop
            autoPlay
            playsInline
            muted
            poster={fallbackImage}
            className={clsx(
              'duration-800 ease-custom h-full w-full object-cover transition-all',
              isExpanded ? 'rounded-[20px]' : 'rounded-l-[20px]'
            )}
          >
            <source src={src} type="video/mp4" />
          </video>

          {showIcons && !isExpanded && isButtonVisible && (
            <Button
              className="absolute bottom-2 left-2 transition-opacity duration-300 sm:bottom-4 sm:left-4 md:bottom-6 md:left-6 lg:bottom-8 lg:left-8"
              onClick={handlePlayClick}
            >
              <Play
                className="h-8 w-8 sm:h-12 sm:w-12 md:h-16 md:w-16"
                weight="fill"
                color="white"
              />
            </Button>
          )}

          {showIcons && isExpanded && isButtonVisible && (
            <Button
              onClick={handleCloseClick}
              className="group absolute bottom-0 right-0 flex h-14 w-14 items-center justify-center rounded-br-xl rounded-tl-xl rounded-bl-none rounded-tr-none bg-base-brand p-3 text-base font-medium text-white transition-opacity duration-300 sm:h-20 sm:w-20 md:h-24 md:w-24" // Modified sizing classes
            >
              <X
                className="h-14 w-14" // Adjusted icon sizes
                weight="bold"
                color="white"
              />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
