import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../publicSite/components/common/Footer/Footer';
import CLPHero from './CLPHero';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { useStrapiCLPData } from '../hooks/useStrapiCLPData';
import CLPServices from './CLPServices';
import { GetInTouchFormMain } from '../../shared/components/GetInTouchForm';
import { CLPTagLine } from './CLPTagline';
import { CLPHQVision } from './CLPHQVision';
import { CLPWhyHQ } from './CLPWhyHQ';

const CombinedLandingPage = () => {
  const { clpSEO } = useStrapiCLPData();
  return (
    <div className="mx-auto flex flex-col items-center bg-page-bg-light [--max-layout-width:1792px] [--common-x-padding:26px] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {clpSEO && <SEOHelmetWrapper seo={clpSEO} />}
      <header className="w-full">
        <Navbar variant="light" />
      </header>
      <main
        className="mb-10 flex w-full max-w-[var(--max-layout-width)] flex-col items-center gap-10 lg:mb-20 lg:gap-14"
        id="main-content"
      >
        <CLPHero className="z-10" />
        <CLPTagLine className="px-[var(--common-x-padding)]" />
        <CLPHQVision className="px-[var(--common-x-padding)]" />
        <div id="hq-services" className="w-full">
          <CLPServices className="z-50 w-full px-[var(--common-x-padding)]" />
        </div>
        <CLPWhyHQ />
        <GetInTouchFormMain className="px-[var(--common-x-padding)]" />
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default CombinedLandingPage;
