import clsx from 'clsx';
import { CheckCircle, Info, Warning, XCircle } from 'phosphor-react';
import { ToastVariant } from '../../types';

interface Props {
  variant: ToastVariant;
  className?: string;
}

const ToastIcon = ({ variant, className }: Props) => {
  switch (variant) {
    case 'info': {
      return (
        <Info
          size={24}
          color="currentColor"
          className={clsx('fill-blue-500 text-blue-500', className)}
        />
      );
    }
    case 'warning': {
      return (
        <Warning
          size={24}
          color="currentColor"
          className={clsx('fill-base-tangerine text-base-tangerine', className)}
        />
      );
    }
    case 'error': {
      return (
        <XCircle
          size={24}
          color="currentColor"
          className={clsx('fill-red-500 text-red-500', className)}
        />
      );
    }
    case 'success': {
      return (
        <CheckCircle
          size={24}
          color="currentColor"
          className={clsx('fill-base-green text-base-green', className)}
        />
      );
    }
  }
};

export default ToastIcon;
