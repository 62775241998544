import { useForm } from 'react-hook-form';

/**
 * Check whether the react-hook-form errors object has a field named fieldName
 */
const fieldHasError = (
  errors: ReturnType<typeof useForm>['formState']['errors'],
  fieldName: string
) => {
  return !!errors[fieldName];
};

export { fieldHasError };
