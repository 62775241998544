import clsx from 'clsx';
import { useStrapiHQSpeakersData } from '../../hooks';
interface props {
  className: string;
}
const SpeakersWelcome = ({ className }: props) => {
  const { allSpeakersPage } = useStrapiHQSpeakersData();
  const {
    title,
    description,
    heroImage: {
      data: {
        attributes: { url: heroImage, alternativeText },
      },
    },
  } = allSpeakersPage[0];

  return (
    <section
      className={clsx(
        'flex w-full flex-col items-start justify-between gap-4 text-base-soft-black md:flex-row',
        className
      )}
    >
      <div className="flex w-full flex-col items-end gap-8 md:flex-row">
        <div className="w-full md:w-1/2">
          <div className="flex max-w-md flex-col justify-center gap-4">
            <h1 className="relative max-w-[360px] break-words text-4xl font-semibold before:dynamic-bottom before:absolute before:-right-2 before:z-[-1] before:hidden before:h-[max(calc(100vh),500px)] before:w-[clamp(500px,calc(100vw/2),var(--max-layout-width))] before:bg-base-soft-beige before:content-[''] md:text-5xl lg:before:inline-block">
              {title}
            </h1>
            <p className="text-base font-medium md:text-lg">{description}</p>
          </div>
        </div>
        <div className="relative aspect-[16/9] h-[300px] w-full overflow-hidden rounded-l-[20px] md:h-[400px] md:w-1/2 lg:h-[500px]">
          <img
            src={heroImage}
            alt={alternativeText}
            className="h-full w-full object-cover object-center md:absolute md:right-[-5%] md:w-[110%] md:rounded-l-[20px]"
          />
        </div>
      </div>
    </section>
  );
};
export default SpeakersWelcome;
