import { useParams } from 'react-router';
import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../publicSite/components/common/Footer/Footer';
import { useStrapiHQSpeakersData } from '../hooks';
import RelatedSpeakerView from './IndividualSpeaker/RelatedSpeakerView';
import IndividualSpeakerView from './IndividualSpeaker/IndividualSpeakerView';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { useEffect, useState } from 'react';
import { SEOProps } from '../../../types';

const HQIndividualSpeakers = () => {
  const { name } = useParams();
  const { allSpeakersList } = useStrapiHQSpeakersData();
  const currentSpeaker = allSpeakersList.data.find(
    (speaker: any) => speaker.attributes.speakerName == name
  ).attributes;
  const [singleSpeakerPageSEO, setSingleSpeakerPageSEO] = useState<SEOProps | undefined>(undefined);

  // Effect hook to update SEO data when coursesQuery.data changes
  useEffect(() => {
    setSingleSpeakerPageSEO({
      pageTitle: currentSpeaker.speakerName,
      pageDescription: currentSpeaker.speakerTopics,
      socialMediaImage: {
        data: {
          attributes: {
            url: currentSpeaker.speakerImage.data.attributes.url,
            alternativeText: currentSpeaker.speakerImage.data.attributes.url,
          },
        },
      },
    });
  }, [currentSpeaker]); // Dependency array includes anything that will trigger a re-run of this effect
  const getSeoData = (defaultSEO: SEOProps, singleSpeakerPageSEO?: SEOProps) => {
    return singleSpeakerPageSEO ?? defaultSEO;
  };
  const { speakersPageSEO } = useStrapiHQSpeakersData();
  return (
    <div className="mx-auto flex flex-col items-center bg-page-bg-light [--max-layout-width:1792px] [--common-x-padding:26px] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {singleSpeakerPageSEO || speakersPageSEO ? (
        <SEOHelmetWrapper seo={getSeoData(speakersPageSEO, singleSpeakerPageSEO)} />
      ) : null}
      <header className="w-full">
        <Navbar showUSHGLogo={true} variant="light" />
      </header>
      <main
        id="main-content"
        className="mb-10 flex w-full max-w-[var(--max-layout-width)] flex-col gap-10 lg:mb-20 lg:gap-20"
      >
        <IndividualSpeakerView
          className="z-10 px-[var(--common-x-padding)]"
          speaker={currentSpeaker}
        />
        <RelatedSpeakerView className="px-[var(--common-x-padding)]" speaker={currentSpeaker} />
      </main>
      <footer className="flex w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default HQIndividualSpeakers;
