import { VideoPlayer } from '../../../../components/Video';
import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';

interface Props {
  className?: string;
  onVideoPlay: (isPlaying: boolean) => void;
}

const CLPHeroIllustration = ({ className, onVideoPlay }: Props) => {
  const { landingPage } = useStrapiCLPData();
  // Extract url and alternativeText, providing defaults to avoid errors
  const videoSrc = landingPage?.CoverVideo?.data?.attributes?.url || '';
  const imgSrc = landingPage?.CoverImage?.data?.attributes?.url || '';
  const alternativeText = landingPage?.CoverVideo?.data?.attributes?.alternativeText || '';

  return (
    <div className={className}>
      <VideoPlayer
        src={videoSrc}
        altText={alternativeText}
        showIcons={true}
        onPlay={onVideoPlay}
        fallbackImage={imgSrc}
      ></VideoPlayer>
    </div>
  );
};

export default CLPHeroIllustration;
