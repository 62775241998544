import clsx from 'clsx';
import { Eye, EyeSlash } from 'phosphor-react';
import React from 'react';
import { useToggle } from '../../hooks';
import CustomInput, { TextInputProps } from './TextInput';

type CustomProps = {
  containerClassName?: string;
  iconClassName?: string;
};

type Props = TextInputProps & CustomProps;

const PasswordInput = React.forwardRef(
  (
    { containerClassName, iconClassName, ...otherProps }: Props,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [isPasswordVisible, togglePasswordVisible] = useToggle(false);

    return (
      <div className={clsx('relative flex h-full w-full items-center', containerClassName)}>
        <CustomInput
          className={clsx('pr-7', otherProps.className)}
          {...otherProps}
          ref={ref}
          type={isPasswordVisible ? 'text' : 'password'}
        />
        {isPasswordVisible ? (
          <EyeSlash
            onClick={togglePasswordVisible}
            className={`absolute right-2 h-5 w-5 cursor-pointer text-zinc-500 ${
              iconClassName ?? ''
            }`}
          />
        ) : (
          <Eye
            onClick={togglePasswordVisible}
            className={clsx('absolute right-2 h-5 w-5 cursor-pointer text-zinc-500', iconClassName)}
          />
        )}
      </div>
    );
  }
);

PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
