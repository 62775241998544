import clsx from 'clsx';
import { SwiperCarouselTypes } from '../../../../types';
import { useStrapiHQSpeakersData } from '../../hooks';
import { HQServiceSpeakerList } from '../../types';
import ContentCardGrid from '../../../../components/ContentCard/ContentCardGrid';
interface props {
  className: string;
  speaker: HQServiceSpeakerList;
}
const RelatedSpeakerView = ({ className, speaker }: props) => {
  const relatedSpeakersList = speaker.hq_speakerslists.data.map(
    (item) => item.attributes.speakerName
  );
  const { individualSpeakersPage } = useStrapiHQSpeakersData();
  const { relatedSpeakersLabel } = individualSpeakersPage[0];
  const { allSpeakersList } = useStrapiHQSpeakersData();

  const data: HQServiceSpeakerList[] = [];
  allSpeakersList.data.forEach((element: { attributes: HQServiceSpeakerList }) => {
    if (relatedSpeakersList.includes(element.attributes.speakerName)) {
      data.push(element.attributes);
    }
  });
  const slides = data.map((item: any, index: number) => {
    return {
      id: index,
      image: item.speakerImage.data.attributes.url,
      duration: item.totalHours,
      name: item.speakerName,
      carouselType: SwiperCarouselTypes.SPEAKERS,
      divElement: (
        <div className="flex flex-col gap-1">
          <p className="text-sm font-light text-base-soft-creme">{item.speakerDesignation}</p>
          <h2 className="text-base font-semibold text-white">{item.speakerName}</h2>
        </div>
      ),
    };
  });
  return slides?.length ? (
    <section className={clsx('flex w-full flex-col gap-6', className)}>
      <h2 className="text-lg font-semibold sm:text-xl">{relatedSpeakersLabel}</h2>
      <ContentCardGrid items={slides} />
    </section>
  ) : (
    <></>
  );
};
export default RelatedSpeakerView;
