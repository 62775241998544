import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pushToGtag } from './analytics';

/**
 * Tracks page views in a React SPA by monitoring route changes.
 */
export const usePageTracking = (): void => {
  const location = useLocation();

  useEffect(() => {
    if (!location) return;

    // Use pushToGtag helper instead of direct gtag call
    pushToGtag('page_view', {
      // eslint-disable-next-line camelcase
      page_location: window.location.href,
      // eslint-disable-next-line camelcase
      page_path: location.pathname + location.search,
      // eslint-disable-next-line camelcase
      page_title: document.title || 'Unknown Title',
    });
  }, [location]);
};
