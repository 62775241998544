import { twMerge } from 'tailwind-merge';
import { useStrapiSharedData } from '../../../../hooks/useStrapiSharedData';

interface Props {
  className?: string;
}

const SkipToMain = (props: Props) => {
  const { className } = props;
  const { skipToMainContentLinkLabel } = useStrapiSharedData();

  return (
    <a
      className={twMerge(
        'absolute top-0 left-1/2 z-50 h-auto w-auto -translate-x-1/2 -translate-y-full overflow-hidden whitespace-nowrap rounded border-none bg-base-brand px-3 py-2 text-sm font-semibold text-white transition-transform focus-visible:translate-y-0',
        className
      )}
      href="#main-content"
    >
      {skipToMainContentLinkLabel}
    </a>
  );
};

export default SkipToMain;
