import { twMerge } from 'tailwind-merge';
import Navbar from '../../../../components/Navbar/Navbar';
import { NavItemMetadata } from '../../../../types';
import { ArrowRight } from 'phosphor-react';

interface Props extends Omit<NavItemMetadata, 'slug'> {
  className?: string;
}

const CLPServicesActionItem = (props: Props) => {
  const { className, to, isExternal, label } = props;

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className={twMerge(
        'group flex items-center gap-x-6 rounded-[10px] border border-base-soft-black px-6 py-3 text-base font-medium text-base-soft-black',
        className
      )}
    >
      <div className="flex items-center justify-between gap-4">
        <span>{label}</span>
        <ArrowRight
          weight="bold"
          className="transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
          size={20}
          aria-hidden="true"
        />
      </div>
    </Navbar.NavItem>
  );
};

export default CLPServicesActionItem;
