import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import clsx from 'clsx';
import { SwiperCarouselTypes } from '../../../../types';
import LogoCarousel from '../../../../components/SwiperCarousel/LogoCarousel';

const CLPHQClientsLogo = (props: { className?: string }) => {
  const {
    whyHQ: { ClientIndustries },
  } = useStrapiCLPData();

  const logos =
    ClientIndustries.clientIndustries.data.map(
      (item: {
        attributes: {
          alternativeText: string;
          url: string;
        };
      }) => {
        return {
          image: item.attributes.url,
          carouselType: SwiperCarouselTypes.LOGOS,
        };
      }
    ) || [];

  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-l-[20px] bg-base-soft-beige py-2 px-3',
        props.className
      )}
    >
      <LogoCarousel logos={logos}></LogoCarousel>
    </div>
  );
};

export default CLPHQClientsLogo;
