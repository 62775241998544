import { useQuery } from '@apollo/client';
import client from '../config/strapi-config';
import useAppDispatch from './use-app-dispatch';
import { PublicSiteQuery } from '../strapiQueries/PublicPageQuery';
import toast from 'react-hot-toast';
import { Toast } from '../components/Toast';
import { setLoading, setStrapiPublicSiteData } from '../features/publicSite/slices/strapi.slice';
import { STRAPI_STALE_TIME } from '../features/shared/constants';

const useStrapiPublicSiteData = () => {
  const dispatch = useAppDispatch();

  const { loading, error, data } = useQuery(PublicSiteQuery, {
    client: client,
    onCompleted: (data) => {
      dispatch(setStrapiPublicSiteData(data));
      dispatch(setLoading(loading));
    },
    onError: async () => {
      const fallbackDataPublicSite = await import('../strapiQueries/fallbackDataPublicSite');
      dispatch(setStrapiPublicSiteData(fallbackDataPublicSite.fallbackDataPublicSite));
      toast.custom(
        (t) => <Toast toastInstance={t} Title="Strapi  Error " SubTitle={''} variant="error" />,
        {
          id: 'StrapiError',
          duration: STRAPI_STALE_TIME,
        }
      );
    },
  });

  return { loading, error, data };
};
export default useStrapiPublicSiteData;
