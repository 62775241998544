import Navbar from '../../../components/Navbar/Navbar';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import Footer from '../../publicSite/components/common/Footer/Footer';
import { useStrapiHQSpeakersData } from '../hooks';
import SpeakersView from './AllSpeakers/SpeakersView';
import SpeakersWelcome from './AllSpeakers/SpeakersWelcome';

const HQSpeakers = () => {
  const { speakersPageSEO } = useStrapiHQSpeakersData();
  return (
    <div className="mx-auto flex flex-col items-center bg-page-bg-light [--max-layout-width:1792px] [--common-x-padding:26px] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {speakersPageSEO && <SEOHelmetWrapper seo={speakersPageSEO} />}
      <header className="w-full">
        <Navbar variant="light" />
      </header>
      <main
        className="mb-10 flex w-full max-w-[var(--max-layout-width)] flex-col gap-10 lg:mb-20 lg:gap-20"
        id="main-content"
      >
        <SpeakersWelcome className="z-10 pl-[var(--common-x-padding)]" />
        <SpeakersView className="px-[var(--common-x-padding)]" />
      </main>
      <footer className="flex w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default HQSpeakers;
