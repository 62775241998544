import clsx from 'clsx';
import { ArrowDown, ArrowRight } from 'phosphor-react';
import { HQServiceItemStrapi } from '../../types';
import HQServicesActionItem from './HQServicesActionItem';
import {
  getThemeStyles,
  ICON_MAP,
  IconComponentType,
  IconName,
  isValidTheme,
} from '../../../../constants/theme.constants';

// Extended IconName type for this component
type ExtendedIconName = IconName | 'Right' | 'Down';

// Extend the icon map with arrow icons
const EXTENDED_ICON_MAP: Record<ExtendedIconName, IconComponentType> = {
  ...ICON_MAP,
  Right: ArrowRight,
  Down: ArrowDown,
};

// Helper function to get icon component
const getExtendedIcon = (icon: string | undefined): IconComponentType | undefined => {
  if (!icon) return undefined;
  return icon in EXTENDED_ICON_MAP ? EXTENDED_ICON_MAP[icon as ExtendedIconName] : undefined;
};

const HQServiceItem = ({ service, index }: { service: HQServiceItemStrapi; index: number }) => {
  const isEven = index % 2 === 0;
  const isBusinessService = service.slug === 'business';

  const theme = isValidTheme(service.serviceTheme) ? service.serviceTheme : 'Brand';
  const themeStyles = getThemeStyles(theme);
  const { buttonClassName } = themeStyles;

  // Buttons
  const buttons = service.Buttons.map((button) => {
    const ButtonIconComponent = getExtendedIcon(button.icon);
    let link = button.link;

    if (button.slug === 'get_in_touch') {
      link = `?engagement_type=consulting_program&program_type=${service.slug}&form_type=getInTouch`;
    }

    return (
      <HQServicesActionItem
        key={button.label}
        label={button.label}
        to={link}
        isExternal={button.isExternal}
        buttonStyle={button.style} // 'fill' or 'outline'
        IconComponent={ButtonIconComponent}
        buttonClassName={buttonClassName}
        state={button.slug === 'get_in_touch' ? { preventScrollToTop: true } : undefined}
      />
    );
  });

  // Image and Service Details
  const src = service.serviceImage.data.attributes.url;
  const alt = service.serviceImage.data.attributes.alternativeText;

  const ImageSection = () => (
    <div
      className={clsx(
        'relative h-[300px] w-full overflow-hidden md:h-[400px] md:w-1/2 lg:h-[400px]'
      )}
    >
      <img
        src={src}
        alt={alt}
        className={clsx(
          'h-full w-full object-cover object-center md:absolute md:right-[-5%] md:w-[110%]',
          {
            '-scale-x-100 md:right-[-5%] md:rounded-r-[20px]': !isEven,
            'md:-left-[5%] md:rounded-r-[20px]': isEven,
          }
        )}
      />
    </div>
  );

  const ContentSection = () => (
    <div
      className={clsx(
        'px-[var(--common-x-padding)] text-base-soft-black md:w-1/2 md:px-0',
        isBusinessService ? 'bg-base-soft-beige' : ''
      )}
    >
      <div className="flex h-full w-full max-w-lg flex-col justify-start gap-8">
        <div className="flex flex-col gap-2">
          <h2 className="text-lg font-medium sm:text-xl">{service.subtitle}</h2>
          <h3 className="text-4xl font-semibold md:text-5xl">{service.title}</h3>
        </div>
        <p className="text-base font-light leading-relaxed">{service.description}</p>
        <div className="flex flex-col gap-4 lg:flex-row">{buttons}</div>
      </div>
    </div>
  );

  const Divider = () => (
    <div className={clsx('hidden w-[2px] md:block', themeStyles.dividerClassName)}></div>
  );

  return (
    <div
      className={clsx(
        'flex flex-col gap-8',
        'md:flex-row',
        isEven ? 'md:pr-[var(--common-x-padding)]' : 'md:pl-[var(--common-x-padding)]',
        !isEven && 'md:flex-row-reverse' // Apply row-reverse for odd items on desktop
      )}
    >
      <ImageSection />
      {isEven ? <Divider /> : null}
      <ContentSection />
      {!isEven ? <Divider /> : null}
    </div>
  );
};

export default HQServiceItem;
