import { gql } from '@apollo/client';
export const TermsAndConditionsFragment = gql`
  fragment TermsAndCondition on TermsAndConditionEntityResponse {
    data {
      attributes {
        title
        infoEmailId
        legalEmailId
        privacyPolicyLink
        lastModifiedLabel
        lastModifiedDate
        section1 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section2 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section3 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section4 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section5 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section6 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section7 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section8 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section9 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section10 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section11 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section12 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section13 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section14 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section15 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section16 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section17 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section18 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section19 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section20 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section21 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section22 {
          title
          policyParagraphs {
            paragraph
          }
        }
        section23 {
          title
          policyParagraphs {
            paragraph
          }
          listSection {
            title
            listPoints {
              paragraph
            }
          }
        }
        section24 {
          title
          policyParagraphs {
            paragraph
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
