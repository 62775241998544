import patternSrc from '../../assets/brand_logo_pattern.png';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

const PatternIllustrator = (props: Props) => {
  return (
    <img
      src={patternSrc}
      alt=""
      className="invisible absolute -bottom-0 -right-0 h-96 md:visible"
      {...props}
    />
  );
};

export default PatternIllustrator;
