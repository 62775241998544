import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// This function checks periodically for the element with the id as given hash in DOM which is awaited in the useEffect
const checkIfRendered = async (hash: string) => {
  if (hash && !document.getElementById(hash.slice(1))) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    await checkIfRendered(hash);
  }
};

const ScrollToTop = ({ children }: React.PropsWithChildren) => {
  const { state, pathname, hash, key } = useLocation();

  useEffect(() => {
    const preventScrollToTop = state?.preventScrollToTop ?? false;
    const scrollToTop = async () => {
      await checkIfRendered(hash);
      if (hash && document.getElementById(hash.slice(1))) {
        const element: HTMLElement | null = document.getElementById(hash.slice(1));
        element && element.scrollIntoView();
      } else if (!preventScrollToTop) {
        window &&
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant' as ScrollBehavior, // Use instant instead of smooth for initial scroll
          });
      }
    };

    scrollToTop();
  }, [state, pathname, hash, key]);

  return <>{children}</>;
};

export default ScrollToTop;
