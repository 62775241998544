export enum SwiperCarouselTypes {
  COURSES = 'COURSES',
  SPEAKERS = 'SPEAKERS',
  ARTICLES = 'ARTICLES',
  LOGOS = 'LOGOS',
  WORKSHOPS = 'WORKSHOPS',
}
export type ContentCardItemType = {
  id?: number;
  name?: string;
  divElement?: JSX.Element;
  label?: string;
  image?: string;
  showNavigation?: boolean;
  carouselType?: SwiperCarouselTypes;
  badgeContent?: string;
  onClick?: () => void;
  className?: string;
  badgeClassName?: string;
  iconClassName?: string;
};
