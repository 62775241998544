import { gql } from '@apollo/client';

export const HQSpeakersFragment = gql`
  fragment HQSpeakersFragment on HqSpeakerEntityResponse {
    data {
      attributes {
        HQSpeakers {
          ... on ComponentHqSpeakersHqSpeakers {
            title
            viewAllSpeakerBtnLabel
          }
        }
        AllSpeakers {
          ... on ComponentHqHeroSection {
            title
            subtitle
            description
            heroImage {
              data {
                attributes {
                  url
                }
              }
            }
            backButtonLabel
          }
        }
        SpeakerDetailView {
          ... on ComponentHqSpeakersSpeakerDetailView {
            coursesLabel
            learnMoreLabel
            inquireLabel
            inquireLabelLink
            learnMoreLink
            speakerTopicLabel
            backButtonLabel
            relatedSpeakersLabel
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
