import React from 'react';
import { BasicSpinner } from '../Spinners';
import { twMerge } from 'tailwind-merge';

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

type CustomProps = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  isBorderless?: boolean;
  // to use custom loading element
  loadingElement?: React.ReactNode;
  buttonIcon?: React.ReactNode;
  buttonIconPosition?: 'FRONT' | 'BACK';
};

type Props = ButtonProps & CustomProps;

const Button = ({
  className,
  children,
  disabled,
  loading = false,
  isBorderless = false,
  buttonIconPosition,
  buttonIcon,
  ...otherProps
}: React.PropsWithChildren<Props>) => {
  return (
    <button
      type="button"
      className={twMerge(
        `flex cursor-pointer flex-row items-center gap-x-6 text-base font-medium
        ${isBorderless ? 'bg-transparent p-0 pb-4 outline-none' : 'rounded px-6 py-3'}
        ${disabled ? 'cursor-not-allowed' : ''}
        ${className}`
      )}
      disabled={disabled}
      {...otherProps}
    >
      {buttonIconPosition == 'FRONT' && buttonIcon}
      {loading ? <BasicSpinner className="h-full text-white" /> : children}
      {buttonIconPosition == 'BACK' && buttonIcon}
    </button>
  );
};
export default Button;
