import { useQuery } from '@apollo/client';
import toast from 'react-hot-toast';
import { Toast } from '../components/Toast';
import client from '../config/strapi-config';
import { setLoading, setStrapiData } from '../features/publicSite/slices/strapi.slice';
import { STRAPI_STALE_TIME } from '../features/shared/constants';
import Query from '../strapiQueries/Query';
import useAppDispatch from './use-app-dispatch';

const useStrapiData = () => {
  const dispatch = useAppDispatch();
  const { loading, error } = useQuery(Query, {
    client: client,
    onCompleted: (data) => {
      dispatch(setStrapiData(data));
      dispatch(setLoading(loading));
    },
    onError: async (error) => {
      const fallbackData = await import('../strapiQueries/fallbackData');
      dispatch(setStrapiData(fallbackData as any));
      toast.custom(
        (t) => (
          <Toast
            toastInstance={t}
            Title="Strapi  Error "
            SubTitle={error.message + error.name}
            variant="error"
          />
        ),
        {
          id: 'StrapiError',
          duration: STRAPI_STALE_TIME,
        }
      );
    },
  });

  return { loading, error };
};
export default useStrapiData;
