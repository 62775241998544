import { Link } from 'react-router-dom';

type props = {
  footer: any;
};

type FooterListItem = {
  footerListItems: string;
  footerListLink: string;
  isExternalLink: boolean;
};

const FooterCard = ({ footer }: props) => {
  const footerHasMultipleLinks = footer.ListItems && footer.ListItems.length > 1;

  // wraps the footer links in a ul if there are multiple links and in a div if there is only one link
  const FooterLinksContainer = footerHasMultipleLinks ? 'ul' : 'div';
  // each link would be a li if there are multiple links and a div if there is only one link
  const FooterLinkContainer = footerHasMultipleLinks ? 'li' : 'div';

  const renderLink = (item: FooterListItem) => {
    const commonClasses = 'block transition-opacity hover:opacity-80';
    const spanClasses = 'inline-block max-w-[120px]';

    if (item.isExternalLink) {
      return (
        <a
          href={item.footerListLink}
          className={commonClasses}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className={spanClasses}>{item.footerListItems}</span>
        </a>
      );
    }

    return (
      <Link to={item.footerListLink} className={commonClasses}>
        <span className={spanClasses}>{item.footerListItems}</span>
      </Link>
    );
  };

  return (
    <div className="mb-6 w-auto shrink-0 font-light">
      <h2 className="mb-2 text-xs uppercase opacity-30 sm:mb-4 sm:text-sm">
        {footer.footerCategory}
      </h2>
      <FooterLinksContainer className="space-y-2 text-xs sm:text-sm">
        {footer.ListItems.map((listItem: FooterListItem, index: number) => {
          return <FooterLinkContainer key={index}>{renderLink(listItem)}</FooterLinkContainer>;
        })}
      </FooterLinksContainer>
    </div>
  );
};

export default FooterCard;
