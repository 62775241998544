import clsx from 'clsx';

interface Props {
  messages?: string;
  className?: string;
  icon?: React.ReactNode;
}

const Message = ({ className, icon, messages }: Props) => {
  return (
    <div>
      {messages && (
        <span
          className={clsx('flex items-center gap-2 text-xs font-normal text-zinc-500', className)}
        >
          <>
            {icon} {messages}
          </>
        </span>
      )}
    </div>
  );
};

export default Message;
