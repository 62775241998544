import clsx from 'clsx';
import { ArrowRight, List, X } from 'phosphor-react';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import DesktopNavMenu from './DesktopNavMenu';
import NavbarMobileMenu from './NavbarMobileMenu';
import NavItem from './NavItem';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import { useMediaQuery } from 'usehooks-ts';
import { Button } from '../Buttons';
interface Props {
  navClassName?: string;
  variant?: 'light' | 'dark';
  className?: string;
  showAuthActions?: boolean;
  showUSHGLogo?: boolean;
}

const Navbar = (props: Props) => {
  const { className, navClassName, variant = 'dark', showUSHGLogo = true } = props;

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  // strapi
  const data = useStrapiDataHelper();
  const {
    mobileHamburgerMenuSRLabel,
    hqHomeLinkSRLabel,
    hqNavButtonLabel,
    hqNavButtonLink,
    HQLogoLight,
    HQLogoDark,
  } = data.nav.data.attributes;

  // strapi

  const toggleMobileNavMenu = () => {
    setShowMobileMenu((show) => !show);
  };

  // use appropriate icon for the mobile menu icon based on its state
  const HamburgerIcon = showMobileMenu ? X : List;
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');
  const HQLogo = variant == 'dark' ? HQLogoDark : HQLogoLight;
  return (
    <div
      className={twMerge(
        clsx(
          'relative flex min-h-[111px] w-full flex-row items-center justify-between px-9',
          variant === 'light' && 'bg-page-bg-light',
          className,
          variant === 'dark' && 'bg-page-bg-dark',
          isLargeScreen ? '' : 'z-40',
          showMobileMenu && !isLargeScreen ? 'bg-base-brand' : ''
        )
      )}
    >
      {/* Hospitality Quotient Logo and Hamburger Menu */}
      <div className="flex items-center gap-4">
        <button
          className="w-max lg:hidden"
          onClick={toggleMobileNavMenu}
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onTouchStart={(event) => {
            event.stopPropagation();
          }}
        >
          <span className="sr-only">{mobileHamburgerMenuSRLabel}</span>
          {/* Revisit */}
          <HamburgerIcon
            className={clsx(
              'absolute right-4 top-16 z-50 block',
              variant === 'light' && 'text-base-soft-black',
              variant === 'dark' && 'text-white'
            )}
            size={24}
            weight="bold"
            color={showMobileMenu ? 'white' : variant == 'dark' ? 'white' : 'black'}
            aria-hidden="true"
          />
        </button>
        <NavItem
          to="/"
          isExternal={false}
          isNavLink={true}
          className={clsx(
            isLargeScreen ? '' : 'w-100 absolute left-0 h-[7rem] rounded-br-[20px]',
            showMobileMenu && variant === 'light' && 'bg-rectangle-light',
            showMobileMenu && variant === 'dark' && 'bg-page-bg-dark'
          )}
        >
          <span className="sr-only">{hqHomeLinkSRLabel}</span>
          <img
            style={{
              height: `${HQLogo.Height}px`,
              width: `${HQLogo.Width}px`,
              objectFit: 'contain',
              marginTop: isLargeScreen ? 'auto' : '-25px',
            }}
            src={HQLogo.Logo.data.attributes.url}
            alt={HQLogo.Logo.data.attributes.alternativeText}
          />
        </NavItem>
      </div>
      {/* Nav Items */}
      <div className="flex items-center gap-7">
        {isLargeScreen ? (
          <DesktopNavMenu className={clsx('relative', navClassName)} variant={variant} />
        ) : null}
        {showUSHGLogo && (
          <NavItem
            to={hqNavButtonLink}
            isExternal={true}
            className={clsx(
              isLargeScreen
                ? 'static'
                : showMobileMenu
                ? 'absolute -top-3 right-0'
                : 'absolute top-0 right-0'
            )}
          >
            <Button
              buttonIconPosition="BACK"
              buttonIcon={
                <ArrowRight
                  size={20}
                  weight="bold"
                  aria-hidden={true}
                  color="currentColor"
                  className="text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
                />
              }
              className={clsx(
                'group w-max justify-center whitespace-nowrap rounded-r-none rounded-l-md bg-base-brand text-base font-medium text-white lg:w-full lg:rounded-[10px]',
                !isLargeScreen &&
                  !showMobileMenu &&
                  'rounded-r-none rounded-bl-[10px] rounded-tl-none',
                showMobileMenu && !isLargeScreen ? 'bg-transparent' : 'bg-base-brand',
                showMobileMenu && !isLargeScreen ? 'bg-transparent' : ''
              )}
            >
              {hqNavButtonLabel}
            </Button>
          </NavItem>
        )}
      </div>

      {showMobileMenu && (
        <NavbarMobileMenu
          // variant={variant}
          // TODO: For now let this be dark for all pages, suggested by @PraveenJuge
          variant={'light'}
          className={clsx('block lg:hidden', navClassName)}
          setShowMobileMenu={setShowMobileMenu}
        />
      )}
    </div>
  );
};

Navbar.NavItem = NavItem;

export default Navbar;
