import { gql } from '@apollo/client';
import { CourseFragment, RegistrationFragment, SharedFragment, TopNavFragment } from './fragments';

const Query = gql`
  ${RegistrationFragment}
  ${TopNavFragment}
  ${CourseFragment}
  ${SharedFragment}
  query {
    registration {
      ...RegistrationFragment
    }
    shared {
      ...SharedFragment
    }
    course {
      ...CourseFragment
    }
    nav {
      ...TopNavFragment
    }
  }
`;

export default Query;
