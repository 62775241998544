import clsx from 'clsx';
import GetInTouchForm from './GetInTouchForm';
import GetInTouchFormHeading from './GetInTouchFormHeading';

interface Props {
  className?: string;
}

const GetInTouchFormMain = ({ className }: Props) => {
  return (
    <section
      id="getInTouchForm"
      className={clsx('flex w-full flex-col items-center justify-center gap-12', className)}
    >
      <GetInTouchFormHeading />
      <GetInTouchForm />
    </section>
  );
};

export default GetInTouchFormMain;
