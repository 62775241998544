import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useStrapiCLPData } from '../../features/combined-landing-page/hooks/useStrapiCLPData';
import { useStrapiDataHelper } from '../../hooks/useStrapiData';
import { NavItemMetaStrapiData } from '../../types';
import NavItem from './NavItem';

interface Props {
  className?: string;
  variant: 'light' | 'dark';
  setShowMobileMenu: (show: boolean) => void;
}

const NavbarMobileMenu = (props: Props) => {
  const { className, variant, setShowMobileMenu } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const data: any = useStrapiDataHelper();
  const strapiNavData = data?.nav.data.attributes;
  const { PublicSiteNavBar: navItems } = strapiNavData;

  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (
        !containerRef.current ||
        (event.target && containerRef.current.contains(event.target as Node))
      ) {
        return;
      }

      // When clicked outside close the menu
      setShowMobileMenu(false);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [setShowMobileMenu]);
  const {
    featureFlag: { showHQPlus, showAboutUs },
  } = useStrapiCLPData();
  return (
    <div className={clsx('absolute inset-x-0 top-full z-50', className)} ref={containerRef}>
      <nav
        className={clsx(
          'flex w-full items-center justify-center bg-base-brand'
          // variant === 'light' && 'bg-rectangle-light',
          // variant === 'dark' && 'bg-rectangle-dark'
        )}
      >
        <ul className={clsx('flex w-full cursor-pointer flex-col')}>
          {navItems.map((navItem: NavItemMetaStrapiData, index: number) => {
            // for hq plus if  isActive = false, we don't want to show the nav item on the screen
            const { isActive, isExternal, label, link, slug } = navItem;
            if (
              (slug === 'hq_plus' && !showHQPlus) ||
              (slug === 'about_us' && !showAboutUs) ||
              !isActive
            ) {
              return null;
            }
            return (
              <li key={slug} className="flex w-full flex-col">
                <NavItem
                  isExternal={isExternal}
                  isNavLink={true}
                  to={link}
                  variant={variant}
                  className="flex w-full flex-1 cursor-pointer justify-end py-3 px-9 text-white"
                >
                  {label}
                </NavItem>
                {index < navItems.length - 1 && (
                  <div className="mx-auto w-[calc(100%-4rem)] border-b-2 border-base-coral-red" />
                )}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default NavbarMobileMenu;
