import clsx from 'clsx';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { NavItemMetadata } from '../../types';

type Props = Omit<NavItemMetadata, 'isActive' | 'slug' | 'label'> & {
  variant?: 'light' | 'dark';
  className?: string;
  isNavLink?: boolean;
  state?: {
    preventScrollToTop?: boolean;
  };
};

const NavItem = (props: React.PropsWithChildren<Props>) => {
  const {
    isExternal,
    children,
    to,
    variant = 'light',
    className,
    isNavLink = false,
    state,
  } = props;

  const location = useLocation();

  const isActive = location.pathname === to;

  const defaultClassName = twMerge(
    clsx(
      'relative flex text-lg',
      variant === 'light' && 'font-semibold text-base-soft-black',
      variant === 'dark' && 'font-semibold text-page-bg-light',
      className
    )
  );

  const underlineClassName = clsx(
    'absolute bottom-0 left-0 h-[3px] w-full bg-base-brand transition-transform duration-200',
    isActive && to !== '/' ? 'scale-x-100' : 'scale-x-0'
  );

  if (isExternal) {
    return (
      <a href={to} className={defaultClassName}>
        {children}
      </a>
    );
  }

  const LinkComponent = isNavLink ? NavLink : Link;

  return (
    <LinkComponent to={to} className={defaultClassName} state={state}>
      <span className="relative z-20">
        {children}
        <span className={underlineClassName} aria-hidden="true" />
      </span>
    </LinkComponent>
  );
};

export default NavItem;
