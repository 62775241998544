import { gql } from '@apollo/client';

export const HQSpeakersListFragment = gql`
  fragment HQSpeakersListFragment on HqSpeakerslistEntityResponseCollection {
    data {
      attributes {
        speakerName
        speakerDescription
        speakerDesignation
        speakerImage {
          data {
            attributes {
              url
              alternativeText
            }
          }
        }
        speakerTopics {
          topic
        }
        numberOfCourses
        isFounder
        hq_speakerslists {
          data {
            attributes {
              speakerName
            }
          }
        }
      }
    }
  }
`;
