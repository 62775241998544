import { SwiperCarouselTypes } from '../../../../types';
import { useStrapiHQSpeakersData } from '../../hooks';
import ContentCardGrid from '../../../../components/ContentCard/ContentCardGrid';

interface props {
  className: string;
}
const SpeakersView = ({ className }: props) => {
  const { allSpeakersList } = useStrapiHQSpeakersData();

  const slides = allSpeakersList.data.map((item: any, index: number) => {
    return {
      id: index,
      image: item.attributes.speakerImage.data.attributes.url,
      duration: item.attributes.totalHours,
      name: item.attributes.speakerName,
      carouselType: SwiperCarouselTypes.SPEAKERS,
      divElement: (
        <div className="flex flex-col gap-1">
          <p className="text-sm font-light text-base-soft-creme">
            {item.attributes.speakerDesignation}
          </p>
          <h2 className="text-base font-semibold text-white">{item.attributes.speakerName}</h2>
        </div>
      ),
    };
  });

  return <ContentCardGrid items={slides} className={className} />;
};
export default SpeakersView;
