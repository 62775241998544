import React from 'react';
import { twMerge } from 'tailwind-merge';

type Option = {
  label: string;
  value: string | number | string[] | null;
};

type SelectFieldProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  className?: string;
  type: 'select';
  options: Option[];
  placeholder?: string;
  defaultValue?: string;
  hasError?: boolean;
};

const DropdownInput = React.forwardRef(
  (props: SelectFieldProps, ref: React.ForwardedRef<HTMLSelectElement>) => {
    const { options, className, placeholder, hasError, defaultValue = '', ...otherProps } = props;
    return (
      <>
        <select
          placeholder={placeholder}
          className={twMerge(
            `peer w-full grow rounded-[10px] border-[1.5px] p-4 indent-2 text-xs text-base-soft-black placeholder:text-xs placeholder:font-light placeholder:text-base-soft-black
            ${className}
            ${
              hasError
                ? '!border-error-border bg-transparent invalid:border-error-border invalid:text-white focus:!border-error-border focus:outline-none focus:invalid:border-error-border'
                : 'border-black bg-transparent'
            }`
          )}
          defaultValue={defaultValue}
          {...otherProps}
          ref={ref}
        >
          {options?.map(({ label, value }) => (
            <option
              disabled={value === '' || value === null}
              key={label?.toString()}
              value={value ?? (null || '')}
            >
              {label}
            </option>
          ))}
        </select>
      </>
    );
  }
);
DropdownInput.displayName = 'DropdownInput';
export default DropdownInput;
