import clsx from 'clsx';
import { useStrapiHQServicesData } from '../../hooks';
import HQServiceItem from './HQServiceItem';
import { HQServiceItemStrapi } from '../../types';

interface Props {
  className?: string;
}

const HQServices = ({ className }: Props) => {
  const { services: Services } = useStrapiHQServicesData();

  // Separate business and individual services
  const businessServices = Services.filter((service: { slug: string }) =>
    service.slug.toLowerCase().includes('business')
  );

  const individualServices = Services.filter((service: { slug: string }) =>
    service.slug.toLowerCase().includes('individual')
  );

  return (
    <div className={clsx('flex w-full flex-col gap-10', className)}>
      {/* Individual Services Section */}
      <div id="individuals" className="flex flex-col gap-10 md:gap-20">
        {individualServices.map((service: HQServiceItemStrapi, index: number) => (
          <HQServiceItem key={service.slug} service={service} index={index} />
        ))}
      </div>

      {/* Business Services Section */}
      <div className="flex flex-col lg:bg-base-soft-beige lg:py-20">
        {businessServices.map((service: HQServiceItemStrapi, index: number) => (
          <div id="businesses" key={service.slug} className="mb-10 last:mb-0 md:mb-20">
            <HQServiceItem service={service} index={index} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default HQServices;
