import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SEOProps } from '../../types';

interface SEOHelmetWrapperProps {
  seo: SEOProps; // The entire SEO configuration is now a single prop
}

const SEOHelmetWrapper = ({ seo }: SEOHelmetWrapperProps) => {
  // Destructure all properties from the seo prop
  const {
    pageTitle,
    pageDescription,
    ogTitle,
    ogDescription,
    ogSiteName,
    canonicalUrl,
    twitterTitle,
    twitterDescription,
    twitterUsername,
    socialMediaImage,
    preventIndexing = false,
    ogType = 'website',
    twitterCardType = 'summary',
  } = seo;

  const { url, alternativeText } = socialMediaImage.data.attributes;

  return (
    <Helmet>
      {/* Standard metadata tags */}
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      {/* End standard metadata tags */}

      {/* OG Meta Tags */}
      <meta property="og:type" content={ogType} />
      <meta property="og:title" content={ogTitle ?? pageTitle} />
      <meta property="og:description" content={ogDescription ?? pageDescription} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:image" content={url} />
      <meta property="og:image:alt" content={alternativeText} />
      {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
      {/* End OG Meta Tags */}

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content={twitterCardType} />
      <meta name="twitter:title" content={twitterTitle ?? pageTitle} />
      <meta name="twitter:description" content={twitterDescription ?? pageDescription} />
      <meta name="twitter:image" content={url} />
      <meta name="twitter:image:alt" content={alternativeText} />
      {twitterUsername && <meta name="twitter:site" content={twitterUsername} />}
      {/* Twitter Meta Tags */}

      {(preventIndexing || process.env.REACT_APP_ENVIRONMENT !== 'prod') && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
  );
};

export default SEOHelmetWrapper;
