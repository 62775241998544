import React from 'react';
import { Link } from 'react-router-dom';
import { useStrapiSharedData } from '../../hooks/useStrapiSharedData';
import Navbar from '../Navbar';

import { Page } from '../Pages';
import SEOHelmetWrapper from '../SEOHelmet/SEOHelmet';

const LandingNotFound = () => {
  const {
    pageNotFound: { title, subTitle, homepageLabel, goBackLabel, orLabel, Seo: pageNotFoundSEO },
  } = useStrapiSharedData();
  return (
    <Page showIllustrator={false}>
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {pageNotFoundSEO && <SEOHelmetWrapper seo={pageNotFoundSEO} />}
      <header>
        <Navbar variant="dark"></Navbar>
      </header>
      <main className="p-4" id="main-content">
        <div className="mx-auto my-8 max-w-md rounded-xl border border-zinc-700 bg-component-bg-dark p-8 shadow-md">
          <h1 className="mb-2 text-2xl font-bold tracking-tight text-page-bg-light">{title}</h1>
          <p className="font-normal leading-6 text-page-bg-light">
            {subTitle}{' '}
            <Link className="underline" to="/">
              {homepageLabel}
            </Link>{' '}
            {orLabel}{' '}
            <Link className="font-extralight underline " to={-1 as any}>
              {goBackLabel}
            </Link>
          </p>
        </div>
      </main>
    </Page>
  );
};

export default LandingNotFound;
