import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

interface Props {
  message: string;
  className?: string;
}

const ErrorMessage = ({ message, className }: Props) => {
  return (
    <span className={twMerge(clsx('text-xs font-normal text-red-500', className))}>{message}</span>
  );
};

export default ErrorMessage;
