import React from 'react';
import './Styles/TextInputNumberStyle.css';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> & {
  className?: string;
  hasError?: boolean;
};

const TextAreaInput = React.forwardRef(
  (
    { className, hasError = false, ...otherProps }: Props,
    ref: React.ForwardedRef<HTMLTextAreaElement>
  ) => {
    return (
      <textarea
        className={twMerge(
          'peer w-full grow rounded-[10px] border-[1.5px] p-3 indent-2 text-sm text-base-soft-black placeholder:text-sm placeholder:font-light placeholder:text-base-soft-black',
          className,
          hasError
            ? 'border-error-border bg-transparent invalid:border-error-border invalid:text-white focus:border-error-border focus:outline-none focus:invalid:border-error-border'
            : 'border-black bg-transparent'
        )}
        ref={ref}
        {...otherProps}
      ></textarea>
    );
  }
);

TextAreaInput.displayName = 'TextAreaInput';

export default TextAreaInput;
export type { Props as TextAreaInputProps };
