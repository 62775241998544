import {
  Graph,
  IconProps,
  Megaphone,
  MonitorPlay,
  UsersThree,
  ArrowRight,
  ArrowLeft,
} from 'phosphor-react';

export type IconComponentType = React.ForwardRefExoticComponent<
  IconProps & React.RefAttributes<SVGSVGElement>
>;

// Define theme names as a union type
export type ThemeName =
  | 'Green'
  | 'Bright_Green'
  | 'Soft_Black'
  | 'Black'
  | 'Brand'
  | 'Tangerine'
  | 'Lime_Yellow'
  | 'Creme';

// Define icon names as a union type
export type IconName = 'Monitor' | 'Megaphone' | 'Graph' | 'People' | 'ArrowRight' | 'ArrowLeft';

// Create a const array of valid themes for type checking
export const VALID_THEMES = [
  'Green',
  'Bright_Green',
  'Soft_Black',
  'Black',
  'Brand',
  'Tangerine',
  'Lime_Yellow',
  'Creme',
] as const;

// Create a const array of valid icons for type checking
export const VALID_ICONS = [
  'Monitor',
  'Megaphone',
  'Graph',
  'People',
  'ArrowRight',
  'ArrowLeft',
] as const;

// Type guard for themes
export const isValidTheme = (theme: string): theme is ThemeName => {
  return VALID_THEMES.includes(theme as ThemeName);
};

// Type guard for icons
export const isValidIcon = (icon: string): icon is IconName => {
  return VALID_ICONS.includes(icon as IconName);
};

// Interface for theme styles
export interface ThemeStyles {
  buttonClassName: string;
  iconClassName: string;
  dividerClassName: string;
  clpServiceIconClassName?: string;
}

// Icon mapping
export const ICON_MAP: Record<
  IconName,
  React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>
> = {
  Monitor: MonitorPlay,
  Megaphone: Megaphone,
  Graph: Graph,
  People: UsersThree,
  ArrowRight: ArrowRight,
  ArrowLeft: ArrowLeft,
};

// Theme styles mapping
export const THEME_MAP: Record<ThemeName, ThemeStyles> = {
  Green: {
    buttonClassName: 'border-base-green bg-base-green text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-green',
    clpServiceIconClassName: 'text-base-green',
  },
  // eslint-disable-next-line camelcase
  Bright_Green: {
    buttonClassName: 'border-base-bright-green bg-base-bright-green text-black text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-bright-green',
    clpServiceIconClassName: 'text-base-bright-green',
  },
  Black: {
    buttonClassName: 'border-black bg-black text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-black',
    clpServiceIconClassName: 'text-white',
  },
  // eslint-disable-next-line camelcase
  Soft_Black: {
    buttonClassName: 'border-base-soft-black bg-base-soft-black text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-soft-black',
    clpServiceIconClassName: 'text-white',
  },
  Brand: {
    buttonClassName: 'border-base-brand bg-base-brand text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-brand',
    clpServiceIconClassName: 'text-base-brand',
  },
  // eslint-disable-next-line camelcase
  Lime_Yellow: {
    buttonClassName: 'border-base-lime-yellow bg-base-lime-yellow text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-lime-yellow',
    clpServiceIconClassName: 'text-base-lime-yellow',
  },
  Tangerine: {
    buttonClassName: 'border-base-tangerine bg-base-tangerine text-white',
    iconClassName: 'text-white',
    dividerClassName: 'bg-base-tangerine',
    clpServiceIconClassName: 'text-base-tangerine',
  },
  Creme: {
    buttonClassName: 'border-base-creme bg-base-creme text-black',
    iconClassName: 'text-black',
    dividerClassName: 'bg-base-creme',
    clpServiceIconClassName: 'text-base-creme',
  },
} as const;

// Helper function to convert string to ThemeName with type safety
export const asThemeName = (theme: string): ThemeName => {
  if (isValidTheme(theme)) {
    return theme;
  }
  return 'Brand'; // Default fallback
};

// Helper function to convert string to IconName with type safety
export const asIconName = (icon: string): IconName | null => {
  if (isValidIcon(icon)) {
    return icon;
  }
  return null;
};

// Helper function to get theme styles
export const getThemeStyles = (theme: string): ThemeStyles => {
  return THEME_MAP[asThemeName(theme)];
};

// Helper function to get icon component
export const getIcon = (icon: string | undefined): IconComponentType | null => {
  if (!icon) return null;
  const iconName = asIconName(icon);
  if (!iconName) return null;
  return ICON_MAP[iconName];
};

// Text color mapping with snake case keys
export const TEXT_COLOR_MAP: Record<string, string> = {
  // eslint-disable-next-line camelcase
  soft_black: 'text-base-soft-black',
  black: 'text-black',
  brand: 'text-base-brand',
  tangerine: 'text-base-tangerine',
  green: 'text-base-green',
  // eslint-disable-next-line camelcase
  bright_green: 'text-base-bright-green',
  // eslint-disable-next-line camelcase
  lime_yellow: 'text-base-lime-yellow',
  creme: 'text-base-creme',
  zinc: 'text-zinc-900',
  white: 'text-white',
};

// Helper function for text colors
export const getTextColor = (theme: string): string => {
  const normalizedTheme = theme.toLowerCase().replace('-', '_');
  return TEXT_COLOR_MAP[normalizedTheme] || TEXT_COLOR_MAP.brand;
};

// Type for components using themes
export interface ThemedComponentProps {
  theme: ThemeName;
  icon?: IconName;
  className?: string;
}

// Helper function to get combined theme styles with additional classes
export const getThemeStylesWithCustom = (
  theme: string,
  additionalClasses?: Record<string, string>
): ThemeStyles => {
  const baseStyles = getThemeStyles(theme);

  if (!additionalClasses) return baseStyles;

  return {
    buttonClassName: `${baseStyles.buttonClassName} ${additionalClasses.button || ''}`.trim(),
    iconClassName: `${baseStyles.iconClassName} ${additionalClasses.icon || ''}`.trim(),
    dividerClassName: `${baseStyles.dividerClassName} ${additionalClasses.divider || ''}`.trim(),
  };
};
