import { configureStore } from '@reduxjs/toolkit';
import strapiReducer from './features/publicSite/slices/strapi.slice';
import pricingReducer from './features/publicSite/slices/pricing.slice';
const store = configureStore({
  reducer: {
    strapi: strapiReducer,
    pricing: pricingReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
