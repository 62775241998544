import clsx from 'clsx';
import Message from './Message';

type HTMLLabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

type CustomProps = {
  label: string;
  labelDescription?: string;
};

type Props = CustomProps & HTMLLabelProps;

const Label = ({ label, labelDescription, className, ...otherProps }: Props) => {
  return (
    <>
      <label
        className={clsx('text-sm font-light leading-4 text-base-soft-black', className)}
        {...otherProps}
      >
        {label}
      </label>
      {labelDescription && <Message messages={labelDescription} />}
    </>
  );
};

export default Label;
