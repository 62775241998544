import React from 'react';
import './Styles/TextInputNumberStyle.css';
import { twMerge } from 'tailwind-merge';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  className?: string;
  type: 'email' | 'text' | 'password' | 'number' | 'range' | 'date';
  hasError?: boolean;
  placeholder?: string;
};

const TextInput = React.forwardRef(
  ({ hasError, placeholder, ...otherProps }: Props, ref: React.ForwardedRef<HTMLInputElement>) => {
    return (
      <input
        className={twMerge(
          `peer w-full grow rounded-[10px] border-[1.5px] p-3 indent-2 text-sm text-base-soft-black placeholder:text-sm placeholder:font-light placeholder:text-base-soft-black
          
          ${
            hasError
              ? '!border-error-border bg-transparent invalid:border-error-border focus:!border-error-border focus:outline-none focus:invalid:border-error-border'
              : 'border-black bg-transparent'
          }`
        )}
        // default a text but could be overrided to password or other types
        ref={ref}
        {...otherProps}
        placeholder={placeholder}
        onKeyDown={(e) => e.stopPropagation()}
      ></input>
    );
  }
);

TextInput.displayName = 'CustomTextInput';

export default TextInput;
export type { Props as TextInputProps };
