import { FadeLoader } from 'react-spinners';
import PatternIllustrator from '../Pages/PatternIllustrator';

export const Loader = () => {
  return (
    <div className="relative flex h-screen w-full items-center justify-center overflow-hidden bg-page-bg-light font-sans text-base-soft-black">
      <PatternIllustrator />
      <FadeLoader color="#42403A" width="3px" height="10px" /> Loading...
    </div>
  );
};
