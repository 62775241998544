import { RootState } from '../store';
import useAppSelector from './use-app-selector';

const strapiDataSelector = (state: RootState) => state.strapi.strapiData;

export const useStrapiSharedData = () => {
  const data: any = useAppSelector(strapiDataSelector);
  const strapiShared = data.shared.data.attributes;
  const pageNotFound = strapiShared.PageNotFound;
  const contactUsEmail = strapiShared.contactUsEmail;
  const Dialog = strapiShared.Dialog;
  const Icon = strapiShared.Icon;
  const skipToMainContentLinkLabel = strapiShared.skipToMainContentLinkLabel;
  const applicationLevelErrorBoundary = strapiShared.ApplicationLevelErrorBoundary;
  const images = strapiShared.images;
  const meetingInfoLink = strapiShared.meetingInfoLink;

  return {
    contactUsEmail,
    pageNotFound,
    Dialog,
    Icon,
    skipToMainContentLinkLabel,
    applicationLevelErrorBoundary,
    images,
    meetingInfoLink,
  };
};
