import { gql } from '@apollo/client';

export const RegistrationFragment = gql`
  fragment RegistrationFragment on RegistrationEntityResponse {
    data {
      attributes {
        pricingTable {
          Pricecard {
            label
            name
            value
            AvatarForpricing {
              data {
                attributes {
                  url
                }
              }
            }
            pricing {
              monthly {
                price
                label
                value
                tenure
              }
              annually {
                price
                label
                value
                tenure
              }
            }
            features {
              Listitem
            }
            buttonWrapperClassName
            buttons {
              label
              icon {
                data {
                  attributes {
                    url
                  }
                }
              }
              value
              className
            }
            role
            description
            className
            badge {
              data {
                attributes {
                  url
                }
              }
            }
            badgelabel
            pricingAltText
          }
        }
        monthy {
          label
          value
          tenure
        }
        annually {
          label
          value
          tenure
        }
        pageHeader
        pageDescription
        signupLabel
        subscriptionLabel
        planLabel
        backLabel
        backToLogin
        contactUsEmailSubject
        planIntervalSwitchSRLabel
      }
    }
  }
`;
