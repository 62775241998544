import clsx from 'clsx';
import { X } from 'phosphor-react';
import { ToastVariant } from '../../types';
import ToastIcon from './ToastIcon';
import { toast, Toast as HotToast } from 'react-hot-toast';

interface Props {
  variant: ToastVariant;
  className?: string;
  Title: React.ReactNode;
  SubTitle?: React.ReactNode;
  toastInstance: HotToast;
}

const getStylesForVariant = (variant: ToastVariant) => {
  switch (variant) {
    case 'info': {
      return {
        borderColorClass: 'border-blue-500',
      };
    }
    case 'warning': {
      return {
        borderColorClass: 'border-base-tangerine',
      };
    }
    case 'error': {
      return {
        borderColorClass: 'border-red-500',
      };
    }
    case 'success': {
      return {
        borderColorClass: 'border-base-green',
      };
    }
  }
};

const Toast = ({ variant, className, Title, SubTitle, toastInstance }: Props) => {
  const stylesForVariant = getStylesForVariant(variant);

  return (
    <div
      className={clsx(
        'z-10 flex w-full max-w-[560px] flex-row gap-4 rounded-sm border-l-4 bg-zinc-800 p-4 shadow-[0px_16px_24px_rgba(0,_0,_0,_0.15)]',
        stylesForVariant.borderColorClass,
        className
      )}
    >
      <ToastIcon className="shrink-0 grow-0" variant={variant} />
      <div className="flex grow flex-col text-sm">
        <div className="font-semibold text-zinc-50">{Title}</div>
        {SubTitle && <div className="font-normal text-zinc-400">{SubTitle}</div>}
      </div>
      <X
        size={24}
        color="currentColor"
        className="shrink-0 grow-0 cursor-pointer fill-zinc-50 text-zinc-50"
        onClick={() => toast.remove(toastInstance.id)}
      />
    </div>
  );
};

export default Toast;
