import { ArrowRight } from 'phosphor-react';
import { ContentCardItemType, SwiperCarouselTypes } from '../../types';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

const ContentCardItem = ({
  id,
  name,
  divElement,
  label,
  image,
  badgeContent,
  onClick,
  carouselType,
  className = '',
  badgeClassName = '',
  iconClassName = '',
}: ContentCardItemType) => {
  const navigate = useNavigate();

  const triggerClick = () => {
    if (carouselType === SwiperCarouselTypes.COURSES) {
      navigate(`/hq/plus/courses/${id}`);
    } else if (carouselType === SwiperCarouselTypes.SPEAKERS) {
      navigate(`/hq/plus/speakers/${name}`);
    } else if (carouselType === SwiperCarouselTypes.WORKSHOPS) {
      onClick?.();
    }
  };

  return (
    <div className="aspect-square h-auto max-h-80 w-full max-w-xs">
      <div
        className={clsx('group h-full w-full cursor-pointer px-2', className)}
        onClick={triggerClick}
      >
        <div className="relative h-full w-full overflow-hidden rounded-2xl">
          {/* Image Container */}
          {image && (
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                src={image}
                alt={label}
              />
              {/* Gradient Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent" />
            </div>
          )}

          {/* Badge */}
          {badgeContent && (
            <div
              className={clsx(
                'absolute left-0 top-4 z-10 rounded-r-lg py-1.5 px-4 text-sm font-medium shadow-lg',
                badgeClassName || 'bg-black/70 text-white'
              )}
            >
              {badgeContent}
            </div>
          )}

          {/* Content Container */}
          <div className="absolute inset-x-0 bottom-0 p-6">
            <div className="flex items-end justify-between gap-4">
              <div className="flex-1 space-y-3">
                {/* Title */}
                <h2 className="text-xl font-bold text-white line-clamp-2">{label}</h2>
                {/* Additional Content */}
                {divElement && <div className="text-sm text-white/90">{divElement}</div>}
              </div>
              {/* Arrow Icon */}
              <div className="shrink-0">
                <ArrowRight
                  className={clsx(
                    'text-white transition-transform duration-300 group-hover:translate-x-1',
                    iconClassName
                  )}
                  size={24}
                  weight="bold"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentCardItem;
