import { RouteObject } from 'react-router-dom';
import { ShowHQPlus } from '../components/common/ShowHQPlus';
import React from 'react';
import { ShowAboutUs } from '../components/common/ShowAboutUs';

import HQSpeakers from '../../hq-speakers/components/HQSpeakers';
import HQIndividualSpeakers from '../../hq-speakers/components/HQIndividualSpeaker';

// Lazy Load components
const LandingPage = React.lazy(() => import('../components/LandingPage/LandingPage'));
const Courses = React.lazy(() => import('../components/Courses/Courses'));
const SingleCourseView = React.lazy(
  () => import('../components/Courses/SingleCourseView/SingleCourseView')
);
const AboutUs = React.lazy(() => import('../components/AboutUs/AboutUs'));
const PrivacyPolicy = React.lazy(() => import('../components/PrivacyPolicy/PrivacyPolicy'));
const EULA = React.lazy(() => import('../components/EULA/EULA'));
const TermsAndConditions = React.lazy(
  () => import('../components/TermsAndConditions/TermsAndConditions')
);
// const WorkshopPage = React.lazy(() => import('../components/Workshops/WorkshopPage'));

const routes: RouteObject[] = [
  {
    path: 'plus',
    element: <ShowHQPlus />,
    children: [
      {
        index: true,
        element: <LandingPage />,
      },
      {
        path: 'courses',
        children: [
          {
            index: true,
            element: <Courses />,
          },
          {
            path: ':id',
            index: true,
            element: <SingleCourseView />,
          },
        ],
      },
      {
        path: 'speakers',
        children: [
          {
            index: true,
            element: <HQSpeakers />,
          },
          {
            path: ':name',
            index: true,
            element: <HQIndividualSpeakers />,
          },
        ],
      },
      // This page is not designed yet
      // {
      //   path: 'about',
      //   element: <ShowHQPlusLaunch />,
      //   children: [
      //     {
      //       children: [
      //         {
      //           index: true,
      //           element: <SizzleVideoPage />,
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
  {
    path: 'about-us',
    element: <ShowAboutUs />,
    children: [
      {
        index: true,
        element: <AboutUs />,
      },
    ],
  },
  // Todo: Remove this after client's confirmation
  // {
  //   path: 'faq',
  //   index: true,
  //   element: <Accordion />,
  // },
  {
    path: 'privacy-policy',
    index: true,
    element: <PrivacyPolicy />,
  },
  {
    path: 'eula',
    index: true,
    element: <EULA />,
  },
  {
    path: 'terms-and-conditions',
    index: true,
    element: <TermsAndConditions />,
  },
];

export default routes;
