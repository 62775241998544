import clsx from 'clsx';
import { PropsWithChildren } from 'react';

interface FormActionButtonProps {
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
}

const FormActionButton = ({
  className,
  children,
  type,
  disabled = false,
}: PropsWithChildren<FormActionButtonProps>) => {
  return (
    <button
      className={clsx(
        'rounded-[10px] px-6 py-3 text-center text-base font-medium text-white',
        className
      )}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default FormActionButton;
