import clsx from 'clsx';
import { ArrowLeft } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { useStrapiHQSpeakersData } from '../../hooks';
import { HQServiceSpeakerList } from '../../types';
import SpeakerActionItem from './SpeakerActionItem';

interface props {
  className: string;
  speaker: HQServiceSpeakerList;
}
const IndividualSpeakerView = ({ className, speaker }: props) => {
  const { individualSpeakersPage } = useStrapiHQSpeakersData();
  const {
    speakerTopicLabel,
    inquireLabel,
    learnMoreLink,
    inquireLabelLink,
    learnMoreLabel,
    backButtonLabel,
  } = individualSpeakersPage[0];
  const paragraphs = speaker.speakerDescription.split('\n').filter(Boolean);

  return (
    <section className={clsx('w-full', className)}>
      <div className="grid w-full grid-cols-1 gap-10 md:grid-cols-[1fr,auto] lg:gap-20">
        <div className="flex flex-col justify-end gap-16">
          {/* Back Link */}
          <Link
            className="flex items-center text-base-soft-black hover:underline"
            to="/hq/plus/speakers"
          >
            <ArrowLeft className="mr-2" size={20} />
            <span>{backButtonLabel}</span>
          </Link>

          {/* Title Section */}
          <div>
            <span className="flex max-w-xs text-sm text-base-brand">
              {speaker.speakerDesignation}
            </span>
            <h1 className="relative max-w-[360px] break-words text-4xl font-semibold text-base-soft-black before:dynamic-bottom before:absolute before:-right-2 before:z-[-1] before:hidden before:h-[max(calc(100vh),500px)] before:w-[clamp(500px,calc(100vw/2),var(--max-layout-width))] before:bg-base-soft-beige before:content-[''] md:text-5xl lg:before:inline-block">
              {speaker.speakerName}
            </h1>
          </div>
        </div>

        {/* Speaker Image */}

        <div className="relative aspect-square h-auto max-h-80 w-full max-w-xs overflow-hidden rounded-[20px] lg:justify-self-end">
          <img
            className="h-full w-full rounded-[20px] object-cover"
            src={speaker.speakerImage.data.attributes.url}
            alt={speaker.speakerImage.data.attributes.alternativeText}
          />
        </div>

        {/* Left Column - Bio */}
        <div className="flex flex-col gap-8">
          {paragraphs.map((paragraph, index) => (
            <p key={index} className="font-light leading-relaxed text-base-soft-black">
              {paragraph}
            </p>
          ))}
        </div>

        {/* Right Column - Topics */}
        <div className="flex h-full w-full max-w-xs flex-col border-l-0 border-base-faded-ash text-base-soft-black md:border-l-[3px] lg:justify-self-end">
          {/* Speaker Topics */}
          {speaker?.speakerTopics?.length && (
            <div className="ml-0 flex flex-1 flex-col gap-4 md:ml-8">
              <h2 className="text-lg font-semibold sm:text-xl">{speakerTopicLabel}</h2>
              <ul className="flex flex-col gap-4">
                {speaker.speakerTopics.map((item, index: number) => (
                  <li key={index} className="flex items-baseline gap-3">
                    <div className="h-2 w-2 shrink-0 rounded-full bg-green-500"></div>
                    <span className="font-light">{item.topic}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="ml-0 flex flex-1 flex-col justify-end gap-4 md:ml-8">
            {/* Action Buttons */}
            <div className="mt-8 flex flex-col justify-end gap-4 align-bottom md:flex-col">
              {speaker.isFounder && (
                <SpeakerActionItem
                  label={learnMoreLabel + ' ' + speaker.speakerName.split(' ')[0]}
                  to={learnMoreLink}
                  isExternal={true}
                  buttonStyle={'fill'} // 'fill' or 'outline'
                  buttonClassName="border-base-brand bg-base-brand text-white"
                  iconClassName="text-white"
                />
              )}
              <SpeakerActionItem
                label={inquireLabel}
                to={`${inquireLabelLink}${speaker.speakerName}&form_type=getInTouch`}
                isExternal={false}
                buttonStyle={'outline'} // 'fill' or 'outline'
                buttonClassName="border-base-soft-black bg-base-soft-black text-white"
                iconClassName="text-base-soft-black"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default IndividualSpeakerView;
