import FooterCard from './FooterCard';
import { ArrowRight, InstagramLogo, LinkedinLogo } from 'phosphor-react';
import { usePublicSiteData } from '../../../hooks/usePublicSiteData';
import { HospitalityQuotientLogo } from '../../../../../components/Logos';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { footerPage } = usePublicSiteData();
  const {
    FooterLists,
    instagramLink,
    linkedInLink,
    hqLogoSRLabel,
    instagramLinkSRLabel,
    HeaderSection,
    NewsLetterSection,
    FooterInfo,
  } = footerPage;

  const year = new Date();
  const { title, Button, description } = NewsLetterSection;
  const { tagline } = HeaderSection;
  const { copyrightText, address } = FooterInfo;
  const { label, link } = Button;

  const finalAllRightReserved = copyrightText.replace('${Year}', year.getFullYear());

  return (
    <footer className="w-full overflow-hidden rounded-t-[20px] text-white">
      <div className="bg-base-brand p-6 sm:p-8 md:px-10 lg:px-16">
        <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
          <div>
            <span className="sr-only">{hqLogoSRLabel}</span>
            <HospitalityQuotientLogo className="h-10 w-auto" variant={'dark'} role="presentation" />
          </div>
          <p className="text-center text-xs font-light sm:text-right sm:text-sm md:text-base lg:text-xl">
            {tagline}
          </p>
        </div>
      </div>

      <div className="bg-base-soft-black px-6 py-8 sm:px-8 sm:py-10 md:px-10 lg:px-16 lg:py-12">
        <div className="flex flex-col justify-between lg:flex-row">
          <div className="mb-8 w-full lg:mb-0 lg:w-[45%]">
            <h3 className="mb-2 text-lg font-medium sm:text-xl">{title}</h3>
            <p className="mb-8 text-xs font-light sm:text-sm">{description}</p>
            <Link
              className="group inline-flex h-min shrink-0 items-center justify-between gap-6 rounded-lg border-2 py-2 px-4 text-sm font-semibold text-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] sm:py-3 sm:px-6 sm:text-base"
              to={link}
            >
              <span>{label}</span>
              <ArrowRight
                size={16}
                weight="bold"
                color="currentColor"
                className="text-inherit transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
                aria-hidden={true}
              />
            </Link>
          </div>

          <div className="flex w-full flex-col justify-between xs:flex-row lg:w-1/2">
            {FooterLists.map((footer: any, index: number) => (
              <FooterCard key={index} footer={footer} />
            ))}
          </div>
        </div>
        <div className="mt-8 mr-3 flex flex-col border-t-2 border-base-slate-gray pt-6 sm:mt-10 lg:mt-12 lg:flex-row lg:justify-between">
          <div className="flex flex-col items-start text-xs font-light sm:flex-row sm:items-baseline sm:justify-between sm:text-sm">
            <div className="mb-2 flex items-baseline gap-2 sm:mb-0">
              <span>{finalAllRightReserved}</span>
              <address className="text-left not-italic sm:text-right">{address}</address>
            </div>
          </div>
          <div className="flex gap-2">
            <a href={instagramLink} rel="nop noreferrer" target="_blank">
              <span className="sr-only">{instagramLinkSRLabel}</span>
              <InstagramLogo
                size={20}
                fill="white"
                color="white"
                weight="regular"
                role="presentation"
              />
            </a>
            <a href={linkedInLink} rel="nop noreferrer" target="_blank">
              <span className="sr-only">{instagramLinkSRLabel}</span>
              <LinkedinLogo
                size={20}
                fill="white"
                color="white"
                weight="regular"
                role="presentation"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
