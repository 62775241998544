import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface strapiState {
  strapiData: any[];
  publicStrapiData: any[];
  loading: boolean;
  error: any;
}
const initialState: strapiState = {
  strapiData: [],
  publicStrapiData: [],
  loading: true,
  error: '',
};

export const strapiSlice = createSlice({
  name: 'strapi',
  initialState,
  reducers: {
    setStrapiData: (state, action: PayloadAction<any[]>) => {
      state.strapiData = action.payload;
    },
    setStrapiPublicSiteData: (state, action: PayloadAction<any>) => {
      state.publicStrapiData = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorState: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
  },
});
export const { setStrapiData, setLoading, setStrapiPublicSiteData, setErrorState } =
  strapiSlice.actions;

export default strapiSlice.reducer;
