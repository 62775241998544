import { NavItemMetadata } from '../../../../../types';
import Navbar from '../../../../../components/Navbar';
import CLPServicesGridItem from './CLPServicesGridItem';
export interface CLPServicesGridItemProps {
  slug: string;
  className?: string;
  Icon: React.ReactNode;
  heading: string;
  illustration: {
    src: string;
    alt: string;
  };
  supportText: string;
  isActive: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  srLabel?: string;
}

interface Props extends Omit<NavItemMetadata, 'slug'> {
  className?: string;
  srLabel?: string;
  heading?: string;
  illustration: {
    src: string;
    alt: string;
  };
  buttonClassName?: string;
  iconClassName?: string;
  serviceGridItem: CLPServicesGridItemProps;
}

const CLPServicesGridActionItem = (props: Props) => {
  const { to, isExternal, className, serviceGridItem, buttonClassName, iconClassName, srLabel } =
    props;

  return (
    <Navbar.NavItem
      isExternal={isExternal}
      to={to}
      className="relative h-full w-full overflow-hidden"
    >
      <CLPServicesGridItem
        to={to}
        isExternal={isExternal}
        {...serviceGridItem}
        buttonClassName={buttonClassName}
        iconClassName={iconClassName}
        className={className}
        srLabel={srLabel}
      />
    </Navbar.NavItem>
  );
};

export default CLPServicesGridActionItem;
