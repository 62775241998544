import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import {
  CombinedLandingPage,
  ContactPage,
  NewsletterPage,
} from '../features/combined-landing-page';
import PublicSiteRoutes from '../features/publicSite/routes';
import ScrollToTop from '../features/shared/components/ScrollToTop/ScrollToTop';
import React from 'react';
import { HQServicesPage } from '../features/hq-services';
import { HQWorkshopsPage } from '../features/hq-workshops';

// Lazy load component
const LandingNotFound = React.lazy(() => import('../components/404Pages/LandingNotFound'));

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ScrollToTop>
        <App />
      </ScrollToTop>
    ),
    children: [
      {
        element: <CombinedLandingPage />,
        index: true,
      },
      {
        path: 'hq',
        children: [
          ...PublicSiteRoutes,
          {
            path: 'services',
            element: <HQServicesPage />,
          },
          {
            path: 'workshops',
            element: <HQWorkshopsPage />,
          },
        ],
      },
      {
        path: 'subscribe-to-newsletter',
        element: <NewsletterPage />,
      },
      {
        path: 'get-in-touch',
        element: <ContactPage />,
      },
      {
        path: '*',
        element: <LandingNotFound />,
      },
    ],
  },
]);

export default router;
