import { gql } from '@apollo/client';

export const CourseFragment = gql`
  fragment CourseFragment on CourseEntityResponse {
    data {
      attributes {
        LANDING_PAGE {
          ... on ComponentLandingPageSingleCourse {
            SingleCourseCard {
              hoursLabel
              ratingsSRLabel
              noRatingsSRLabel
              courseLinkSRLabel
              relatedCoursesLabel
            }
            TopNav {
              allCoursesButton
              subscribeNowButton
            }
            SpeakerDetails {
              title
              courseLabel
            }
            CourseInfo {
              minsLabel
              hoursLabel
              dueLabel
              reviewLabel
              moduleLabel
              courseDetailsLabel
            }
            ModuleDetails {
              hoursLabel
              minsLabel
              moduleLabel
            }
            CourseDetails {
              descriptionLabel
              categoryLabel
              tagsLabel
              courseOutComeLabel
              similarCoursesLabel
              noTagsLabel
            }
          }
        }
      }
    }
  }
`;
