import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import { useMediaQuery } from 'usehooks-ts';
import CLPWhyHQArrow from './CLPWhyHQArrow';
import { TEXT_COLOR_MAP } from '../../../../constants/theme.constants';

interface ApproachStatement {
  statementText: string;
  textColor: string;
}

const CLPWhyHQIllustration = () => {
  const { whyHQ } = useStrapiCLPData();
  const {
    title,
    ApproachStatements,
    ClientIndustries: { clientIndustriesHeading },
  } = whyHQ;

  const textContent = ApproachStatements.map((text: ApproachStatement, index: number) => (
    <span key={index} className={TEXT_COLOR_MAP[text.textColor]}>
      {text.statementText}
      {index < ApproachStatements.length - 1 && ' '}
    </span>
  ));

  const isDesktop = useMediaQuery('(min-width: 1024px)');

  // const ArrowDashes = ({ className }: ArrowDashesProps) => (
  //   <div className={clsx('flex items-center', className)}>
  //     <Minus weight="bold" size={60} className="text-base-brand" />
  //     <Minus weight="bold" size={30} className="text-base-soft-black" />
  //     <Minus weight="bold" size={30} className="text-base-soft-black" />
  //     <ArrowRight weight="bold" size={30} className="text-base-soft-black" />
  //   </div>
  // );
  return whyHQ ? (
    <div className="flex flex-1 items-start gap-6">
      {isDesktop && (
        <div className="h-full">
          <CLPWhyHQArrow />
        </div>
      )}
      <div className="flex h-full flex-1 flex-col gap-4">
        <div className="flex items-start justify-between">
          <h2 className="text-4xl font-semibold md:text-5xl">{title}</h2>
          {/* <ArrowDashes className="hidden md:flex" /> */}
        </div>
        <div className="flex flex-1 flex-col justify-between text-left font-medium">
          <p className="text-base lg:text-lg xl:text-xl">{textContent}</p>
        </div>
        <div className="text-base-soft-blac flex font-light">
          <p className="text-lg">{clientIndustriesHeading}</p>
        </div>
        {/* <ArrowDashes className="flex md:hidden" /> */}
      </div>
    </div>
  ) : null;
};

export default CLPWhyHQIllustration;
