import { twMerge } from 'tailwind-merge';
import CLPServicesGrid from './CLPServicesGrid';
import CLPServicesHeading from './CLPServicesHeading';

interface Props {
  className?: string;
  id?: string;
}

const CLPServices = (props: Props) => {
  const { className } = props;

  return (
    <section className={twMerge('relative flex w-full flex-col gap-10', className)}>
      <CLPServicesHeading />
      <CLPServicesGrid />
    </section>
  );
};

export default CLPServices;
