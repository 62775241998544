import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import NavItemDropDown from '../Navbar/NavItemDropDown';
import { NavLink } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

type props = {
  label: string;
  content: any;
  variant: 'light' | 'dark';
  className?: string;
  contentSideOffset?: number;
};
const DropDownMenu = (props: props) => {
  const { variant = 'light', className, label, content, contentSideOffset } = props;

  const defaultClassName = twMerge(
    clsx(
      'text-base',
      variant === 'light' && 'font-medium text-zinc-900',
      variant === 'dark' && 'font-normal text-white',
      className
    )
  );
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <NavItemDropDown className={defaultClassName} variant={variant}>
          {label}
        </NavItemDropDown>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={contentSideOffset}
          className="isolate z-50 rounded-lg border border-zinc-200 bg-white p-1 drop-shadow-xl"
        >
          {content.map((item: { label: string; slug: string; link: string }) => (
            <DropdownMenu.Item
              key={item.slug}
              className="pointer-events-auto cursor-pointer rounded-md hover:bg-zinc-100 hover:focus:border-zinc-700"
            >
              <NavLink className="flex py-2.5 px-3 text-sm font-normal leading-4" to={item.link}>
                {item.label}
              </NavLink>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default DropDownMenu;
