import { gql } from '@apollo/client';

export const TopNavFragment = gql`
  fragment TopNavFragment on NavEntityResponse {
    data {
      attributes {
        logInLabel
        signUpLabel
        mobileHamburgerMenuSRLabel
        hqHomeLinkSRLabel
        ushgHomeLinkSRLabel
        hqNavButtonLabel
        hqNavButtonLink
        PublicSiteNavBar {
          label
          isExternal
          link
          isActive
          slug
          parentElement
        }
        USHGLogoLight {
          Logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Height
          Width
        }
        USHGLogoDark {
          Logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Height
          Width
        }
        HQLogoLight {
          Logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Height
          Width
        }
        HQLogoDark {
          Logo {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          Height
          Width
        }
      }
    }
  }
`;
