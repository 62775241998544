import { gql } from '@apollo/client';

export const HQWorkshopFragment = gql`
  fragment HQWorkshopFragment on HqWorkshopEntityResponse {
    data {
      attributes {
        HeroSection {
          title
          subtitle
          description
          heroImage {
            data {
              attributes {
                url
              }
            }
          }
          backButtonLabel
        }
        AllWorkshops {
          ... on ComponentInpersonWorkshopWorkshop {
            WorkshopList {
              workshopTitle
              upcomingLabel
              workshopFeaturesListTitle
              purchaseTicketsButtonLabel
              workshopDescription
              workshopCoverImage {
                data {
                  attributes {
                    url
                    alternativeText
                  }
                }
              }
              fromDate
              toDate
              eventLabel
              bookingLink
              purchaseLimitMessage
              location
              attendeeRange
              speakerTitle
              Speakers {
                speakerName
                speakerRole
                speakerImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
              DayWiseActivitiesForWorkhop {
                day
                activity
              }
              WorkhopFeatures {
                title
                feature
              }
              price
              isActive
              dayWiseActivitiesTitle
              workshopDetailsTitle
            }
          }
        }
        Seo {
          pageTitle
          pageDescription
          ogTitle
          ogDescription
          ogSiteName
          canonicalUrl
          twitterTitle
          twitterDescription
          twitterUsername
          socialMediaImage {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          ogType
          twitterCardType
          preventIndexing
        }
      }
    }
  }
`;
