import { ArrowDown, ArrowRight } from 'phosphor-react';
import { useStrapiHQServicesData } from '../../hooks';
import clsx from 'clsx';
import { useCallback } from 'react';
import { ServiceExplorerData } from '../../types';
import HQServicesActionItem from './HQServicesActionItem';
import pluralize from 'pluralize';
import {
  getThemeStyles,
  ICON_MAP,
  IconComponentType,
  IconName,
  isValidTheme,
} from '../../../../constants/theme.constants';

interface Props {
  className?: string;
}

// Extended IconName type to include specific arrow directions
type ExtendedIconName = IconName | 'Right' | 'Down';

// Extend the icon map to include specific directional arrows
const EXTENDED_ICON_MAP: Record<ExtendedIconName, IconComponentType> = {
  ...ICON_MAP,
  Right: ArrowRight,
  Down: ArrowDown,
};

// Type guard for extended icons
const isValidExtendedIcon = (icon: string): icon is ExtendedIconName => {
  return icon in EXTENDED_ICON_MAP;
};

// Helper function to get icon component that returns undefined instead of null
const getExtendedIcon = (icon: string | undefined): IconComponentType | undefined => {
  if (!icon) return undefined;
  return isValidExtendedIcon(icon) ? EXTENDED_ICON_MAP[icon] : undefined;
};

const ServiceExplorer = ({ className }: Props) => {
  const { servicExplorer } = useStrapiHQServicesData();
  const { infoText, infoTextTheme, Buttons } = servicExplorer as ServiceExplorerData;

  const renderInfoText = useCallback(() => {
    const parts = infoText.split(/(\{\{.*?\}\})/);
    return parts.map((part, index) => {
      const match = part.match(/\{\{(.*?)\}\}/);
      if (match) {
        const buttonSlug = match[1];
        const button = Buttons.find((b) => b.slug === buttonSlug);
        if (button) {
          return (
            <span key={index} className="text-base font-light text-base-brand sm:text-lg">
              {pluralize.singular(button.label)}
            </span>
          );
        }
      }
      return part;
    });
  }, [infoText, Buttons]);

  // Get theme styles using our helper function, with Brand as fallback
  const theme = isValidTheme(infoTextTheme) ? infoTextTheme : 'Brand';
  const themeStyles = getThemeStyles(theme);

  const buttons = Buttons.map((button) => {
    const ButtonIconComponent = getExtendedIcon(button.icon);

    return (
      <HQServicesActionItem
        key={button.label}
        label={button.label}
        to={button.link}
        isExternal={button.isExternal}
        buttonStyle={button.style} // 'fill' or 'outline'
        IconComponent={ButtonIconComponent}
        buttonClassName={clsx(themeStyles.buttonClassName)}
      />
    );
  });

  return (
    <div className={clsx('pl-[var(--common-x-padding)] text-base sm:text-lg', className)}>
      <div className="flex flex-col items-center justify-between gap-4 rounded-[20px] bg-base-soft-beige p-6 font-light md:flex-row lg:rounded-l-[20px] lg:rounded-r-none">
        <h2 className="text-center text-base text-base-soft-black md:text-lg">
          {renderInfoText()}
        </h2>
        <div className="flex flex-col gap-4 xs:flex-row">{buttons}</div>
      </div>
    </div>
  );
};

export default ServiceExplorer;
