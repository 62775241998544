import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;
export const useStrapiHQServicesData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const hqServices = data.hqService.data.attributes;

  const hqServicesHeroSection = hqServices.HeroSection;
  const services = hqServices.Services;
  const servicExplorer = hqServices.UserTypeSelector;
  const Seo = hqServices.Seo;
  return {
    hqServicesHeroSection,
    services,
    servicExplorer,
    Seo,
  };
};
