import { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import { Swiper as SwiperClass } from 'swiper/types';
import 'swiper/css';

interface LogoCarouselProps {
  logos: Array<{
    image: string;
    carouselType: string;
  }>;
  className?: string;
}

const LogoCarousel = ({ logos }: LogoCarouselProps) => {
  const swiperRef = useRef<SwiperClass>();

  // Generate duplicates for continuous scroll
  const duplicatedLogos = [...logos, ...logos, ...logos, ...logos];

  return (
    <div className="relative h-[180px] w-full">
      {' '}
      {/* Increased container height */}
      <div className="absolute inset-0 flex items-center">
        <div className="w-full overflow-hidden">
          <Swiper
            modules={[Autoplay]}
            slidesPerView={6}
            spaceBetween={40} // Increased space between slides
            loop={true}
            speed={3000}
            autoplay={{
              delay: 0,
              disableOnInteraction: false,
            }}
            allowTouchMove={false}
            watchSlidesProgress={true}
            observer={true}
            observeParents={true}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="!h-[100px]" // Increased swiper height
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 40,
              },
              1800: {
                slidesPerView: 6.5,
                spaceBetween: 40,
              },
              2400: {
                slidesPerView: 8,
                spaceBetween: 40,
              },
            }}
          >
            {duplicatedLogos.map((logo, index) => (
              <SwiperSlide
                key={index}
                className="flex !h-[100px] items-center justify-center" // Increased slide height
              >
                <div className="flex h-full items-center justify-center">
                  <img
                    src={logo.image}
                    alt={`Client logo ${index + 1}`}
                    className="h-auto max-h-[80px] w-auto max-w-[180px] object-contain" // Increased logo dimensions
                    draggable="false"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;
