import clsx from 'clsx';
import CLPHeroContent from './CLPHeroContent';
import CLPHeroIllustration from './CLPHeroIllustration';
import { CaretDoubleDown } from 'phosphor-react';
import { Button } from '../../../../components/Buttons';
import { useState } from 'react';
interface Props {
  className?: string;
}

const CLPHero = ({ className }: Props) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const handleVideoPlay = (isPlaying: boolean) => {
    setIsVideoPlaying(isPlaying);
  };

  const handleScroll = () => {
    const section = document.getElementById('hq-services');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <section
      className={clsx(
        'relative flex w-full flex-col items-start justify-between gap-4 md:flex-row',
        isVideoPlaying ? 'px-[var(--common-x-padding)]' : 'pl-[var(--common-x-padding)]',
        className
      )}
    >
      <div className="flex w-full flex-col items-end gap-8 md:flex-row">
        <CLPHeroContent
          className={clsx('justify-start', isVideoPlaying ? 'w-full' : 'w-full md:w-1/2')}
          isVideoPlaying={isVideoPlaying}
        />
        <CLPHeroIllustration
          className={clsx(isVideoPlaying ? 'w-full' : 'w-full md:w-1/2')}
          onVideoPlay={handleVideoPlay}
        />
      </div>

      {!isVideoPlaying && (
        <div
          className="absolute right-0 top-full -z-50 -mt-20 hidden h-80 w-60 cursor-pointer flex-col items-center justify-center rounded-[20px] bg-rectangle-light xl:flex"
          onClick={handleScroll}
        >
          <Button className="flex h-full w-full items-center justify-center p-4">
            <CaretDoubleDown
              size={64}
              weight="thin"
              className="text-base-brand transition-transform group-hover:translate-y-2 motion-reduce:group-hover:translate-y-0"
            />
          </Button>
        </div>
      )}
    </section>
  );
};

export default CLPHero;
