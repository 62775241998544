declare global {
  interface Window {
    gtag: (command: string, event: string, params?: any) => void;
  }
}

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'prod';

export interface GtagEvent {
  [key: string]: any;
}

export const pushToGtag = (eventName: string, params: GtagEvent): void => {
  if (!IS_PRODUCTION) {
    console.info('Event not pushed (not in production):', eventName, params);
    return;
  }

  if (!window.gtag) {
    console.error('Error: gtag is not initialized.');
    return;
  }

  try {
    window.gtag('event', eventName, params);
    console.log('Event pushed:', eventName, params);
  } catch (error) {
    console.error('Error pushing event:', error, eventName, params);
  }
};
