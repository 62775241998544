import { Outlet } from 'react-router-dom';
import LandingNotFound from '../../../../components/404Pages/LandingNotFound';
import { useStrapiCLPData } from '../../../combined-landing-page/hooks/useStrapiCLPData';

export const ShowAboutUs = () => {
  const {
    featureFlag: { showAboutUs },
  } = useStrapiCLPData();
  if (showAboutUs) {
    return <Outlet></Outlet>;
  } else {
    return <LandingNotFound></LandingNotFound>;
  }
};
