import { gql } from '@apollo/client';
export const PrivacyPolicyFragment = gql`
  fragment PrivacyPolicyRichText on PrivacyPolicyRichTextEntityResponse {
    data {
      attributes {
        data
      }
    }
  }
`;
