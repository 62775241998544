import { useStrapiCLPData } from '../../hooks/useStrapiCLPData';
import CLPServicesActionItem from './CLPServicesActionItem';

const CLPServicesHeading = () => {
  const {
    ourServices: { title, OurServiceTitle, Buttons: actionItem },
  } = useStrapiCLPData();
  return (
    <header className="flex flex-col items-start justify-between xs:flex-row xs:items-center sm:items-center">
      <div className="mb-4 flex flex-col gap-1 text-base-soft-black sm:mb-0">
        <span className="text-xl font-medium sm:text-2xl">{OurServiceTitle}</span>
        <h2 className="text-4xl font-semibold md:text-5xl">{title}</h2>
      </div>
      <CLPServicesActionItem
        label={actionItem.label}
        isExternal={actionItem.isExternal}
        to={actionItem.link}
        {...actionItem}
        className="border-2"
      />
    </header>
  );
};

export default CLPServicesHeading;
