import { ArrowRight, IconProps } from 'phosphor-react';

type Props = IconProps & React.RefAttributes<SVGSVGElement>;

const ButtonIcon = (props: Props) => {
  return (
    <ArrowRight
      weight="bold"
      className="transition-transform group-hover:translate-x-1"
      {...props}
    />
  );
};

export default ButtonIcon;
