import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { CaretDown } from 'phosphor-react';

type Props = {
  variant?: 'light' | 'dark';
  className?: string;
};

const NavItemDropDown = (props: React.PropsWithChildren<Props>) => {
  const { children, variant = 'light', className } = props;

  const defaultClassName = twMerge(
    clsx(
      'text-sm',
      variant === 'light' && 'font-medium text-zinc-900',
      variant === 'dark' && 'font-normal text-white',
      className
    )
  );
  return (
    <div className="flex flex-nowrap items-center gap-1.5">
      <a className={defaultClassName}>{children}</a>
      <CaretDown size={14} weight="regular" color="#71717A" />
    </div>
  );
};

export default NavItemDropDown;
