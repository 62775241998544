import clsx from 'clsx';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import { ServiceItemStrapi } from '../../../types';
import { Props as CLPServicesGridItemProps } from './CLPServicesGridItem';
import CLPServicesGridActionItem from './CLPServicesGridActionItem';
import {
  getThemeStyles,
  ICON_MAP,
  IconName,
  isValidIcon,
  isValidTheme,
} from '../../../../../constants/theme.constants';

const CLPServicesGrid = () => {
  const {
    ourServices: { Services },
    featureFlag: { showHQPlus, lastServiceCardTakesFullWidth },
  } = useStrapiCLPData();

  const serviceGridItems = [...Services].map((service: ServiceItemStrapi) => {
    const theme = isValidTheme(service.theme) ? service.theme : 'Brand';
    const themeStyles = getThemeStyles(theme);
    const { buttonClassName, clpServiceIconClassName } = themeStyles;

    const IconComponent =
      service.Icon && isValidIcon(service.Icon) ? ICON_MAP[service.Icon as IconName] : null;

    const Icon = IconComponent ? (
      <IconComponent
        size={48}
        color="currentColor"
        weight="fill"
        className={clpServiceIconClassName}
        aria-hidden={true}
      />
    ) : null;
    const slug = service.slug;
    const heading = service.title;
    const src = service.serviceImage.data.attributes.url;
    const alt = service.serviceImage.data.attributes.alternativeText;
    const supportText = service.description;
    let isActive = service.isActive;
    if (service.slug === 'hq_plus') {
      isActive = showHQPlus && service.isActive;
    }
    const isExternal = service.isExternal;
    const to = service.link;
    return {
      Icon,
      heading,
      illustration: {
        src,
        alt,
      },
      supportText,
      isActive,
      isExternal,
      to,
      slug,
      buttonClassName,
      clpServiceIconClassName,
    };
  });
  const activeServiceGridItems = serviceGridItems.filter(
    (serviceGridItem: CLPServicesGridItemProps) => serviceGridItem.isActive
  );

  const hasOddServiceGridItems = activeServiceGridItems.length % 2 == 1;
  const loneCardIndex = lastServiceCardTakesFullWidth ? activeServiceGridItems.length - 1 : 0;

  const showBackgroundDecoration = activeServiceGridItems.length > 3;

  return (
    <div
      className={clsx(
        'grid auto-rows-auto grid-cols-1 justify-center gap-[var(--grid-gap,2.5rem)] md:grid-cols-2',
        'justify-items-center'
      )}
    >
      {activeServiceGridItems.map((serviceGridItem: CLPServicesGridItemProps, idx: number) => {
        if (!serviceGridItem.isActive) {
          return null;
        }

        let isLoneCard = false;

        // If we have multiple odd items then one would be left out so span the last one two rows
        if (hasOddServiceGridItems && idx === loneCardIndex) {
          isLoneCard = true;
        }

        return (
          <CLPServicesGridActionItem
            serviceGridItem={serviceGridItem}
            label={serviceGridItem.supportText}
            key={serviceGridItem.heading}
            {...serviceGridItem}
            className={clsx(
              'w-full',
              isLoneCard && 'col-span-1 md:relative md:left-[calc(50%_+_var(--grid-gap,2.5rem))]'
            )}
            buttonClassName={serviceGridItem.buttonClassName}
            iconClassName={serviceGridItem.clpServiceIconClassName}
          />
        );
      })}
      {showBackgroundDecoration && (
        <div className="absolute right-0 top-0 -z-10 hidden h-1/2 w-1/2 rounded-l-[20px] bg-rectangle-light xl:block"></div>
      )}
    </div>
  );
};

export default CLPServicesGrid;
