import clsx from 'clsx';
import { useStrapiCLPData } from '../../../hooks/useStrapiCLPData';
import { TEXT_COLOR_MAP } from '../../../../../constants/theme.constants';

interface Props {
  className?: string;
  isVideoPlaying?: boolean;
}

// Content in the Hero Section
const CLPHeroContent = ({ isVideoPlaying }: Props) => {
  const {
    landingPage: { Title: titleArray },
  } = useStrapiCLPData();

  const titleParts = titleArray.map((title: { label: string; color: string }) => {
    const label = title.label;
    const color = title.color;
    const className = TEXT_COLOR_MAP[color];
    return { label, className };
  });

  return !isVideoPlaying ? (
    <div className="w-full md:w-1/2">
      <div className="flex max-w-md flex-col justify-center gap-4">
        <h1 className="relative max-w-[360px] break-words text-4xl font-semibold text-base-soft-black before:dynamic-bottom before:absolute before:-right-2 before:z-[-1] before:hidden before:h-[max(calc(100vh),500px)] before:w-[clamp(500px,calc(100vw/2),var(--max-layout-width))] before:bg-base-soft-beige before:content-[''] md:text-5xl lg:text-7xl xl:before:inline-block">
          {titleParts.map((titlePart: { label: string; className: string }, idx: number) => {
            const isLastPart = idx === titleParts.length - 1;

            return (
              <span
                key={titlePart.label}
                className={clsx('inline-block sm:whitespace-nowrap', titlePart.className)}
              >
                {titlePart.label}
                {!isLastPart && <span className="whitespace-pre"> </span>}
              </span>
            );
          })}
        </h1>
        <hr className="ml-2 mt-6 hidden h-20 w-[2px] border-0 bg-base-brand md:block" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CLPHeroContent;
