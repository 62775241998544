import clsx from 'clsx';
import { useFormStyle } from '../../features/shared/context/form-style-context';

type HTMLLabelProps = React.DetailedHTMLProps<
  React.LabelHTMLAttributes<HTMLLabelElement>,
  HTMLLabelElement
>;

type CustomProps = {
  label: string;
};

type Props = CustomProps & HTMLLabelProps;

const FloatingLabel = ({ label, className, ...otherProps }: Props) => {
  const formStyle = useFormStyle();
  return (
    <>
      <label
        className={clsx(
          'absolute left-4 z-10 origin-[0] -translate-y-4 scale-75 cursor-text select-none px-2 text-lg font-semibold text-base-soft-black duration-300',
          // Always use these styles, since the label should float
          'top-1 peer-focus:top-1 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-2',
          formStyle.backgroundColor,
          className // Additional classes if provided
        )}
        {...otherProps}
      >
        {label}
      </label>
    </>
  );
};

export default FloatingLabel;
