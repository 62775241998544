import Navbar from '../../../components/Navbar/Navbar';
import Footer from '../../publicSite/components/common/Footer/Footer';
import SEOHelmetWrapper from '../../../components/SEOHelmet/SEOHelmet';
import { GetInTouchFormMain } from '../../shared/components/GetInTouchForm';
import { HQServicesHero } from './HQServicesHero';
import HQServices from './HQServices/HQServices';
import ServiceExplorer from './HQServices/ServiceExplorer';
import { useStrapiHQServicesData } from '../hooks';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const HQServicesPage = () => {
  const location = useLocation();
  const { Seo: hqServicesPageSeo } = useStrapiHQServicesData();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const contactUsForm = searchParams.get('form_type');
    if (contactUsForm === 'getInTouch') {
      document.getElementById('getInTouchForm')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location.search, location.key]);

  return (
    <div className="mx-auto flex flex-col items-center bg-page-bg-light [--max-layout-width:1792px] [--common-x-padding:26px] xs:[--common-x-padding:52px] xl:[--common-x-padding:116px]">
      {/* SEOHelmetWrapper Component: Dynamically sets the document's head elements such as title, meta description, and other meta tags for Search Engine Optimization (SEO) purposes. */}
      {hqServicesPageSeo && <SEOHelmetWrapper seo={hqServicesPageSeo} />}
      <header className="w-full">
        <Navbar variant="light" />
      </header>
      <main
        className="mb-10 flex w-full max-w-[var(--max-layout-width)] flex-col gap-10 lg:mb-20 lg:gap-20 "
        id="main-content"
      >
        <HQServicesHero className="z-10 pl-[var(--common-x-padding)]" />
        <ServiceExplorer className="px-[var(--common-x-padding)] lg:pl-[var(--common-x-padding)] lg:pr-0" />
        <HQServices />
        <GetInTouchFormMain className="px-[var(--common-x-padding)]" />
      </main>
      <footer className="w-full">
        <Footer />
      </footer>
    </div>
  );
};

export default HQServicesPage;
