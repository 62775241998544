import { gql } from '@apollo/client';
export const BrandLogoFragment = gql`
  fragment BrandLogoFragment on SideNavigatorEntityResponse {
    data {
      attributes {
        logo {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
`;
