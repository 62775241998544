import clsx from 'clsx';
import React from 'react';
import { useStrapiHQFormData } from '../../hooks/useStrapiHQFormData';
import { TEXT_COLOR_MAP } from '../../../../constants/theme.constants';

type TitlePart = {
  label: string;
  className: string;
};

interface GetInTouchFormHeadingProps {
  className?: string;
}

const GetInTouchFormHeading = ({ className }: GetInTouchFormHeadingProps) => {
  const { GetInTouch } = useStrapiHQFormData();
  const { Title: titleArray, formDescription } = GetInTouch;

  const titleParts: TitlePart[] = titleArray.map((title: { label: string; color: string }) => {
    const label = title.label;
    const color = title.color;
    const className = TEXT_COLOR_MAP[color];
    return { label, className };
  });

  return (
    <header
      className={clsx(
        'flex w-full flex-col items-center justify-between xs:flex-row xs:items-center md:items-center',
        className
      )}
    >
      <div
        className="flex w-full flex-col gap-4 text-left text-base-soft-black sm:mb-0 md:text-center"
        id="contact-main"
      >
        <h2 className="flex flex-wrap items-center justify-start gap-1 text-4xl font-semibold tracking-[-0.02rem] text-base-soft-black md:justify-center md:text-5xl">
          {titleParts?.map((titlePart: { label: string; className: string }, idx: number) => {
            let addSpace = false;
            if (idx !== titleParts.length - 1) {
              addSpace = true;
            }

            return (
              <span
                key={titlePart.label}
                className={clsx('sm:whitespace-nowrap', titlePart.className)}
              >{`${titlePart.label}${addSpace ? ' ' : ''}`}</span>
            );
          })}
        </h2>
        <p className="text-base">{formDescription}</p>
      </div>
    </header>
  );
};

export default GetInTouchFormHeading;
