import clsx from 'clsx';
import { ArrowRight } from 'phosphor-react';
import { twMerge } from 'tailwind-merge';

export interface Props {
  slug: string;
  className?: string;
  Icon: React.ReactNode;
  heading: string;
  illustration: {
    src: string;
    alt: string;
  };
  supportText: string;
  isActive: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  clpServiceIconClassName?: string;
  srLabel?: string;
  to: string;
  isExternal: boolean;
}

const CLPServicesGridItem = (props: Props) => {
  const { className, heading, illustration, buttonClassName, iconClassName, srLabel } = props;

  return (
    <article
      className={twMerge(
        clsx(
          'group flex h-full w-full flex-col', // Added group for hover effects
          className
        )
      )}
    >
      <div
        className={clsx(
          'relative h-full w-full overflow-hidden rounded-t-[1.25rem] border-b-4',
          buttonClassName
        )}
      >
        <div className="relative h-full overflow-hidden">
          {' '}
          {/* Added overflow-hidden */}
          <img
            src={illustration.src}
            alt={illustration.alt}
            className="aspect-[16/9] h-full w-full object-cover transition-transform duration-700 ease-in-out group-hover:scale-110" // Added zoom effect
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent" />
          <div className="absolute bottom-0 left-0 flex w-full items-center justify-between p-6">
            <h3 className="text-lg font-semibold text-white sm:text-xl md:text-2xl">{heading}</h3>
            <div
              className={clsx(
                iconClassName,
                'group flex items-center justify-center gap-1 text-base font-light shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)]'
              )}
            >
              <ArrowRight
                className="relative transition-transform group-hover:translate-x-1 motion-reduce:group-hover:translate-x-0"
                size={32}
                aria-label={srLabel}
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default CLPServicesGridItem;
