import React from 'react';
import clsx from 'clsx';
import ContentCardItem from './ContentCardItem';
import { ContentCardItemType } from '../../types';

interface ContentCardGridProps {
  items: ContentCardItemType[];
  className?: string;
}

const ContentCardGrid = ({ items, className = '' }: ContentCardGridProps) => {
  return (
    <section
      className={clsx(
        'grid w-full auto-rows-auto grid-cols-[repeat(auto-fill,_minmax(15rem,_1fr))] justify-center gap-8',
        className
      )}
    >
      {items?.map((item: ContentCardItemType, index: number) => (
        <ContentCardItem key={`card_${item.id || item.name || index}`} {...item} />
      ))}
    </section>
  );
};

export default ContentCardGrid;
