import { CaretDown } from 'phosphor-react';
import { components, DropdownIndicatorProps } from 'react-select';

// Custom DropdownIndicator
const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDown className="text-base-soft-black" weight="fill" size={18} />{' '}
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
