import axios from 'axios';
import { API_BASE_URL, USERS_API_PATH } from '../constants';
import { HubSpotForm } from '../types';
import qs from 'qs';

export const submitHubspotForm = async (data: HubSpotForm, formType: string | undefined) => {
  const path = USERS_API_PATH.SUBMIT_HUBSPOT_FORM;
  const queryString = qs.stringify({ formType: formType });
  const endpoint = `${API_BASE_URL}${path}?${queryString}`;

  const response = await axios.post(endpoint, data, {
    withCredentials: true,
  });
  return response;
};
