import { useAppSelector } from '../../../hooks';
import { RootState } from '../../../store';

const strapiDataSelector = (state: RootState) => state.strapi.publicStrapiData;
export const useStrapiHQSpeakersData = () => {
  const data: Record<string, any> = useAppSelector(strapiDataSelector);
  const speakersPage = data.hqSpeaker.data.attributes;
  const allSpeakersList = data.hqSpeakerslists;
  const individualSpeakersPage = speakersPage.SpeakerDetailView;
  const allSpeakersPage = speakersPage.AllSpeakers;
  const hqSpeakers = speakersPage.HQSpeakers;
  const speakersPageSEO = speakersPage.seo;
  return {
    speakersPage,
    allSpeakersList,
    individualSpeakersPage,
    allSpeakersPage,
    hqSpeakers,
    speakersPageSEO,
  };
};
